@import '../common/variables';
@import '../common/mixins';

.filter {
  display   : flex;
  flex-flow : row nowrap;

  .filter-column {
    flex-basis      : 250px;

    display         : flex;
    flex-flow       : column;
    justify-content : flex-start;
    align-items     : flex-start;

    position        : relative;
    width           : 100%;
    //height: 100%;
    background      : @white;
    border-radius   : 8px;
    //padding         : 0 0 20px;
    box-shadow      : @shadow;

    &.nobg {
      background : none;
    }
  }
  .filter-column-collapse {
    position   : relative;
    width      : 100%;
    padding    : 0 20px 0;
    //max-height : 48px;
    transition : padding-bottom .3s ease-out;

    &.opened {
      padding : 0 20px 20px;
      .filter-collapse-header {
        &:after {
          transform : rotate(180deg);
        }

      }
      .filter-column-collapse-list {
        max-height     : 800px;
        padding-top    : 1px;
        padding-bottom : 2px;
      }
    }

    &:not(:first-child) {
      border-top : 1px solid @lite-gray;
    }
    .filter-column-collapse-header {
      position    : relative;
      width       : 100%;
      //height       : 40px;
      padding     : 10px 0;
      cursor      : pointer;

      color       : @dark-blue;
      font-size   : 14px;
      font-weight : 700;
      line-height : 1.1;
      &:after {
        content    : '';
        display    : block;
        position   : absolute;
        top        : 0;
        bottom     : 0;
        margin     : auto 0;
        right      : -12px;
        width      : 12px;
        height     : 10px;
        .svg_stroke(@svg-chevron-up, @dark-blue);
        transform  : rotate(0deg);
        transition : transform .3s ease-out;
      }
    }
    .filter-column-collapse-header-left {
      flex            : 1 1 80%;

      display         : flex;
      justify-content : flex-start;
      align-items     : center;
      gap             : 30px;

      position        : relative;

    }
    .checkbox-block {
      margin-top : 15px;
      padding    : 0;

      font-size  : 12px;
      &:first-child {
        margin-top : 0;
      }
    }

    .filter-column-collapse-list {
      max-height     : 0;
      padding-top    : 0;
      padding-bottom : 0;
      transition     : max-height .3s ease-out;
      overflow       : hidden;
    }
  }

  .filter-result {
    flex-grow : 1;

    display   : flex;
    flex-flow : column;
    gap       : 20px;

    position  : relative;
    padding   : 0 0 0 20px;

    .fr-element {
      position      : relative;
      width         : 100%;
      border-radius : 8px;
      background    : @white;
      overflow      : hidden;
      box-shadow    : @shadow;
      transition    : box-shadow .4s ease-out;
      &:hover {
        box-shadow : @shadow3;
      }
      &.opened {
        .fr-element-details {
          height : auto;
        }
        .fr-arrow {
          transform : rotate(0deg);
          //border-left  : 1px solid #F6F6F6;
          //border-right : none;
        }
      }
      .fr-element-inner {
        display    : flex;
        flex-flow  : row nowrap;

        position   : relative;
        width      : 100%;
        min-height : 90px;
        cursor     : pointer;
      }
      .fr-element-img {
        align-self: stretch;

        position     : relative;
        width        : 120px;
        //height        : 75px;
        //height       : 100%;
        //aspect-ratio : 1;
      }
      .fr-element-data {
        flex-grow : 1;

        position  : relative;
        height    : 100%;
        padding   : 10px 20px;
      }
      .fr-element-data2 {
        display         : flex;
        justify-content : flex-end;
        align-items     : flex-end;
        flex-flow       : column;
        gap             : 5px;
        min-width       : 180px;
        .ce-price {
          .ce-price-old{
            margin-bottom: 0;
          }
          .ce-price-new span:first-child {
            font-size : 18px;
          }
        }
      }
      .fr-element-category {
        margin-bottom : 5px;

        font-size     : 14px;
      }
      .fr-element-title {
        margin-bottom : 5px;

        font-weight   : 700;
      }
      .fr-arrow {
        position        : relative;
        width           : 16px;
        height          : 16px;
        background      : url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M12.5906 9.98913C12.3951 10.1846 12.0791 10.1846 11.8836 9.98913L6.73716 4.84274L1.59078 9.98913C1.39529 10.1846 1.07929 10.1846 0.883799 9.98913C0.688311 9.79364 0.688311 9.47764 0.883799 9.28215L6.38369 3.78226C6.48119 3.68476 6.60918 3.63576 6.73719 3.63576C6.8652 3.63576 6.99319 3.68476 7.09069 3.78226L12.5906 9.28215C12.786 9.47764 12.786 9.79364 12.5906 9.98913Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='12' height='12' fill='white' transform='matrix(1 0 0 -1 0.737183 12.8857)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center/contain;
        //background      : url("/img/down_arrow.svg") no-repeat center;
        //border-right    : 1px solid #F6F6F6;
        cursor          : pointer;
        //padding         : 10px 20px;
        transform       : rotate(180deg);

        //flex            : 0 0 5%;

        display         : flex;
        justify-content : center;
        align-items     : center;

        font-size       : 16px;
        font-weight     : bold;
        transition      : transform .3s ease-out;

      }
      .ce-price {
        display         : flex;
        flex-flow       : row;
        align-items     : flex-end;
        justify-content : flex-end;
        gap             : 10px;

        position        : relative;
        //min-height      : 45px;
        margin-bottom   : 10px;
        .ce-price-old {
          position      : relative;
          display       : inline-block;
          margin-bottom : 5px;

          color         : @dark-gray;
          font-weight   : 500;
          line-height   : 1;
          //text-decoration : line-through;
          &:after {
            content    : '';
            display    : block;
            position   : absolute;
            width      : 110%;
            height     : 1px;
            background : @dark-gray;
            left       : -5%;
            top        : 50%;
          }
          > span {
            &:first-child {
              margin-right : 3px;
              font-size    : 14px;
            }
            font-size : 11px;
          }
        }
        .ce-price-new {
          color       : @dark-blue;
          font-weight : 500;
          line-height : 1;
          > span {
            &:first-child {
              margin-right : 3px;
              font-size    : 24px;
              font-weight  : 700;
            }
            font-size : 14px;
          }
        }
      }

      .fr-element-details {
        position : relative;
        width    : 100%;
        height   : 0;
        overflow : hidden;

        .fr-element-details-crop {
          display         : flex;
          justify-content : flex-start;
          align-items     : flex-start;
          flex-flow       : column;
          gap             : 10px;

          position        : relative;
          width           : 100%;
          padding         : 10px;

          background      : #e0e0e0;

          .fr-element-details-element {
            display         : flex;
            justify-content : flex-start;
            align-items     : flex-start;
            flex-flow       : row nowrap;

            position        : relative;
            width           : 100%;
            height          : 125px;
            //border          : solid 1px @lite-lite-gray;
            border-radius   : 8px;
            overflow        : hidden;
            background      : @white;
            box-shadow      : @shadow;

            .fr-element-details-img {
              flex-basis : 125px;

              height     : 100%;
            }
            .fr-element-details-data {
              flex-grow : 1;

              position  : relative;
              height    : 100%;
              padding   : 20px;
              .fr-element-details-title {
                margin-bottom : 5px;

                font-weight   : 700;
              }
            }
            .fr-element-details-price {
              text-align : right;
              span:first-child {
                margin-right : 10px;

                font-weight  : 700;
              }
            }
          }
        }
        .fr-element-data2 {
          align-self      : stretch;

          justify-content : center;
          align-items     : stretch;
          min-width       : 180px;
        }
      }
    }
  }
}

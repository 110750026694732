.composition {
  position              : relative;
  margin-top            : 30px;
  padding-right         : 10px;
  display               : grid;
  grid-template-columns : 1fr 1fr;
  gap                   : 0 30px;
  @media all and (max-width : 1024px) {
    grid-template-columns : 1fr;
    gap                   : 0;
  }

  &.no-border {
  }

  //& > div {
  //  display               : grid;
  //  grid-template-columns : 1fr 1fr;
  //  gap                   : 0px 30px;
  //  @media all and (max-width : 1024px) {
  //    grid-template-columns : 1fr;
  //    gap                   : 0px;
  //  }
  //}
  &.single-column {
    grid-template-columns : 1fr;
    gap                   : 10px;
    .composition-row {
      margin-bottom : 0;
      &:after {
        content    : '';
        position   : absolute;
        height     : 0;
        background : #a7adb7;
        width      : 90%;
        left       : 5%;
        bottom     : -15px;
      }
      &:nth-child(2n-1) {
        &:before {
          content    : '';
          position   : absolute;
          height     : 90%;
          background : #a7adb7;
          width      : 0;
          top        : 5%;
          right      : -15px;
          @media all and (max-width : 1024px) {
            display : none;
          }
        }
      }

      .input-enter {
        position        : relative;
        width           : 100%;
        display         : flex;
        flex-flow       : row wrap;
        justify-content : start;
        align-items     : center;
        .title {
          display       : inline-block;
          //margin-right  : 0;
          margin-bottom : 0;
        }
        .input {
          margin-bottom : 0;
          &.grow {
            input {
              width : 100%;
            }
          }
          input {
            padding          : 0 15px;
            border-radius    : 8px;
            height           : 30px;
            width            : 200px;
            color            : #4f5a69;
            background-color : #ffffff;
            cursor           : text;
            //border           : 1px solid #94ACC2;
            border           : none;
            box-shadow       : @shadow;

            font-size        : 14px;
            &:focus {
              outline : none;
            }
          }
        }
      }
    }
  }
  .composition-row {
    position      : relative;
    width         : 100%;
    margin-bottom : 30px;

    &:after {
      content    : '';
      position   : absolute;
      height     : 1px;
      background : #a7adb7;
      width      : 90%;
      left       : 5%;
      bottom     : -15px;
    }
    &:nth-child(2n-1) {
      &:before {
        content    : '';
        position   : absolute;
        height     : 90%;
        background : #a7adb7;
        width      : 1px;
        top        : 5%;
        right      : -15px;
        @media all and (max-width : 1024px) {
          display : none;
        }
      }
    }

    .input-select {
      position        : relative;
      width           : 100%;
      display         : flex;
      flex-flow       : row wrap;
      justify-content : start;
      align-items     : baseline;

      margin-bottom   : 20px;
      .title {
        display       : inline-block;
        margin-right  : 15px;
        margin-bottom : 10px;
      }
      .select {
        display       : inline-block;
        position      : relative;
        top           : auto;
        left          : auto;
        width         : auto;
        margin-bottom : 10px;
        &.grow {
          input {
            width : 100%;
          }
        }
      }
      .items-list-holder {
        width : 100%
      }
    }
    .input-enter {
      position        : relative;
      width           : 100%;
      display         : flex;
      flex-flow       : row wrap;
      justify-content : start;
      align-items     : baseline;
      .title {
        display       : inline-block;
        margin-right  : 15px;
        margin-bottom : 10px;
      }
      .input {
        margin-bottom : 20px;
        &.grow {
          input, textarea {
            width : 100%;
          }
        }
        input {
          padding          : 0 15px;
          border-radius    : 8px;
          height           : 30px;
          width            : 200px;
          color            : #4f5a69;
          background-color : #ffffff;
          cursor           : text;
          //border           : 1px solid #94ACC2;
          border           : none;
          box-shadow       : @shadow;

          font-size        : 14px;
          &:focus {
            outline : none;
          }
        }
        textarea {
          display          : inline-block;
          padding          : 5px 15px;
          resize           : vertical;
          border-radius    : 8px;
          height           : 30px;
          width            : 200px;
          color            : #4f5a69;
          background-color : #ffffff;
          cursor           : text;
          //border           : 1px solid #94ACC2;
          border           : none;
          box-shadow       : @shadow;

          font-size        : 14px;
          &:focus {
            outline : none;
          }
        }
      }
    }
  }
}

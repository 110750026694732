@import '../common/variables';
@import '../common/mixins';

.hint {
  position        : fixed;
  background      : @white;
  border-radius   : 6px;
  padding         : 5px;
  box-shadow      : @shadow3;

  display         : flex;
  justify-content : center;
  align-items     : center;

  font-size       : 12px;
  font-weight     : 500;
  color           : @dark-blue;
  text-align      : center;

  z-index         : 150;
}
@import '../common/variables';
@import '../common/mixins';

.left-menu-btn {
  --s: 20px; /* control the size */
  --c: black; /* the color */

  height: var(--s);
  aspect-ratio: 1;
  border: none;
  padding: 0;
  border-inline: calc(var(--s) / 2) solid #0000;
  box-sizing: content-box;
  --_g1: linear-gradient(var(--c) 20%, #0000 0 80%, var(--c) 0) no-repeat content-box border-box;
  --_g2: radial-gradient(circle closest-side at 50% 12.5%, var(--c) 95%, #0000) repeat-y content-box border-box;
  background: var(--_g2) left var(--_p, 0px) top,
  var(--_g1) left calc(var(--s) / 10 + var(--_p, 0px)) top,
  var(--_g2) right var(--_p, 0px) top,
  var(--_g1) right calc(var(--s) / 10 + var(--_p, 0px)) top;
  background-size: 20% 80%,
  40% 100%;
  //position: relative;
  clip-path: inset(0 25%);
  -webkit-mask: linear-gradient(90deg, #0000, #000 25% 75%, #0000);
  cursor: pointer;
  transition: background-position .3s var(--_s, .3s),
  clip-path 0s var(--_s, .6s),
  left .35s ease-out,
  color .35s ease-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  display: none;
  position: fixed;
  top: 4px;
  left: 0;
  z-index: 99;

  .resp(@tablet-big, {
    display: block;
  });
}

.left-menu-btn:before,
.left-menu-btn:after {
  content: "";
  position: absolute;
  border-radius: var(--s);
  inset: 40% 0;
  background: var(--c);
  transition: transform .3s calc(.3s - var(--_s, .3s));
}

.left-menu-btn:checked {
  left: calc(@paddingMenu - 45px);
  --c: white;
  clip-path: inset(0);
  --_p: calc(-1 * var(--s));
  --_s: 0s;

  //transition: background-position .3s var(--_s, .3s),
  //clip-path 0s var(--_s, .6s),
  //left .35s ease-out;
}

.left-menu-btn:checked:before {
  transform: rotate(45deg);
}

.left-menu-btn:checked:after {
  transform: rotate(-45deg);
}

.left-menu-btn:focus-visible {
  clip-path: none;
  -webkit-mask: none;
  border: none;
  outline: 2px solid var(--c);
  outline-offset: 5px;
}

.left-menu-btn:checked + .left-menu {
  left: 0;
  .resp(@tablet-big, {
    left: 0;
  });
}

.left-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: @paddingMenu;
  padding: 25px 0 25px;
  //padding    : 105px 0 25px;
  height: 100vh;
  background: @left-menu-color url('@{path-img}/bg-main.svg') no-repeat center/cover;
  overflow: auto;
  transition: left .4s ease-out;


  flex-basis: @paddingMenu;

  color: #FFFFFF;

  .resp(@tablet-big, {
    padding: 45px 0 25px;
    left: -@paddingMenu;
    height: -webkit-fill-available;
  });


  .logo {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    height: 55px;
    background: url('@{path-img}/logo.png') no-repeat center / contain;

    /*@media only screen and (min-width : 320px) and (-webkit-min-device-pixel-ratio : 1.3), only screen and (min-width : 320px) and (   min--moz-device-pixel-ratio : 1.3), only screen and (min-width : 320px) and (     -o-min-device-pixel-ratio : 4/3), only screen and (min-width : 320px) and (        min-device-pixel-ratio : 1.3), only screen and (min-width : 320px) and (                min-resolution : 124dpi), only screen and (min-width : 320px) and (                min-resolution : 1.3dppx) {
      background : url('../images/logo@2x.png') no-repeat center / contain;
    }*/
  }

  .lm-element {
    position: relative;
    width: 100%;

    .lm-element-button {
      display: flex;
      align-items: center;
      gap: 12px;

      position: relative;
      //height      : 32px;

      //position    : relative;
      padding: 15px 25px 15px 25px;
      //border-bottom : 1px solid #3B4452;
      cursor: pointer;
      transition: background-color .3s ease-in-out;

      &.root-element {
        padding: 24px 25px 24px 0;
        text-transform: uppercase;
      }

      &:hover {
        //padding-left : 30px;
        //border-left : 3px solid #3B4452;
        //background-color : #484960;
        background-color: #25346C;
      }

      &.active {
        //border-left      : 3px solid #546072;
        //padding-left     : 30px;
        background-color: #F2F5F9;
        color: #222222;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 6px;
          background: #2D3657;
          border-bottom-right-radius: 6px;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 6px;
          background: #2D3657;
          border-top-right-radius: 6px;
        }
      }

      &.selected {
        background: #F2F5F9;
      }

      .lm-element-arrow {
        position: relative;
        margin: 0 15px;
        width: 13px;
        height: 13px;
        background: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M12.5906 9.98913C12.3951 10.1846 12.0791 10.1846 11.8836 9.98913L6.73716 4.84274L1.59078 9.98913C1.39529 10.1846 1.07929 10.1846 0.883799 9.98913C0.688311 9.79364 0.688311 9.47764 0.883799 9.28215L6.38369 3.78226C6.48119 3.68476 6.60918 3.63576 6.73719 3.63576C6.8652 3.63576 6.99319 3.68476 7.09069 3.78226L12.5906 9.28215C12.786 9.47764 12.786 9.79364 12.5906 9.98913Z' fill='%23fff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='12' height='12' fill='white' transform='matrix(1 0 0 -1 0.737183 12.8857)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center;

        &.hidden {
          transform: rotate(180deg);
        }
      }

      &.icon-orders {
        .lm-icon {
          content: '';
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          .svg(@svg-lm-orders, @white);
        }

        &.active .lm-icon {
          .svg(@svg-lm-orders, #2D3657);
        }
      }

      &.icon-customers {
        .lm-icon {
          content: '';
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          .svg(@svg-lm-customers, @white);
        }

        &.active .lm-icon {
          .svg(@svg-lm-customers, #2D3657);
        }
      }

      &.icon-from-site {
        .lm-icon {
          content: '';
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          .svg(@svg-lm-from-site, @white);
        }

        &.active .lm-icon {
          .svg(@svg-lm-from-site, #2D3657);
        }
      }

      &.icon-catalog {
        .lm-icon {
          content: '';
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          .svg(@svg-lm-catalog, @white);
        }

        &.active .lm-icon {
          .svg(@svg-lm-catalog, #2D3657);
        }
      }

      &.icon-properties {
        .lm-icon {
          content: '';
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          .svg(@svg-lm-properties, @white);
        }

        &.active .lm-icon {
          .svg(@svg-lm-properties, #2D3657);
        }
      }

      &.icon-infopages {
        .lm-icon {
          content: '';
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          .svg(@svg-lm-infopages, @white);
        }

        &.active .lm-icon {
          .svg(@svg-lm-infopages, #2D3657);
        }
      }

      &.icon-blog {
        .lm-icon {
          content: '';
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          .svg(@svg-lm-blog, @white);
        }

        &.active .lm-icon {
          .svg(@svg-lm-blog, #2D3657);
        }
      }

      &.icon-tags {
        .lm-icon {
          content: '';
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          .svg(@svg-lm-tags, @white);
        }

        &.active .lm-icon {
          .svg(@svg-lm-tags, #2D3657);
        }
      }

      &.icon-erp-users {
        .lm-icon {
          content: '';
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          .svg(@svg-lm-erp-users, @white);
        }

        &.active .lm-icon {
          .svg(@svg-lm-erp-users, #2D3657);
        }
      }

      &.icon-money {
        .lm-icon {
          content: '';
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          .svg(@svg-lm-money, @white);
        }

        &.active .lm-icon {
          .svg(@svg-lm-money, #2D3657);
        }
      }

      &.icon-logout {
        .lm-icon {
          content: '';
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          .svg(@svg-lm-logout, @white);
        }

        &.active .lm-icon {
          .svg(@svg-lm-logout, #2D3657);
        }
      }

    }

    .lm-element-dd {
      position: relative;
      padding-left: 20px;
      //background   : #666666;
      max-height: 2000px;
      transition: max-height ease-in-out .15s;
      overflow: hidden;

      &.hidden {
        max-height: 0;
      }
    }
  }
}

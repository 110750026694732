.svg(@src, @fill-new) {
  @data-uri: data-uri('image/svg+xml;charset=UTF-8','@{src}.svg');
  @replace-default: escape('<path ');
  @replace-new: escape('<path fill="@{fill-new}" ');
  @replace-src: replace('@{data-uri}', @replace-default, @replace-new, 'g');
  background-image: e(@replace-src);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
}

.svg_cls(@src, @fill-new) {
  @data-uri: data-uri('image/svg+xml;charset=UTF-8','@{src}.svg');
  @replace-default: escape('.cls{}');
  @replace-new: escape('.cls{fill:@{fill-new}}');
  @replace-src: replace('@{data-uri}', @replace-default, @replace-new, 'g');
  background-image: e(@replace-src);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
}

.svg_stroke(@src, @stroke-new) {
  @data-uri: data-uri('image/svg+xml;charset=UTF-8','@{src}.svg');
  @replace-default: escape('<path ');
  @replace-new: escape('<path stroke="@{stroke-new}" ');
  @replace-src: replace('@{data-uri}', @replace-default, @replace-new, 'g');
  background-image: e(@replace-src);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
}

.resp(@maxWidth; @rules) {
  @media only screen and (max-width : @maxWidth) {
    @rules();
  }
}

@path-img              : '../images';
@path-ico              : '../icons';
@paddingMenu           : 250px;
@paddinglist           : 250px;
@font                  : 'Open Sans', sans-serif;

@main-bg               : #F2F5F9;
@white                 : #FFFFFF;
@lite-lite-gray        : #ECECEC;
@lite-gray             : #D8D8D8;
@dark-gray             : #777777;
@dark-blue             : #000000;
@left-menu-color       : #2D3657;
@left-menu-color-hover : #2F428C;
@base-blue             : #253985;
@base-blue-hover       : #1E559F;
@base-blue-80          : #1E3A8ACC;

@mate-aqua             : #CFF7F8;
@mate-green            : #6DA085;
@mate-light-green      : #94D3B3;
@mate-yellow           : #E9BF78;
@mate-red              : #C1465C;
@mate-red-dark         : #de173b;
@mate-blue             : #5072cb;

@svg-lm-orders         : '@{path-ico}/ico-lm-orders';
@svg-lm-customers      : '@{path-ico}/ico-lm-customers';
@svg-lm-requests       : '@{path-ico}/ico-lm-requests';
@svg-lm-from-site      : '@{path-ico}/ico-lm-from-site';
@svg-lm-catalog        : '@{path-ico}/ico-lm-catalog';
@svg-lm-properties     : '@{path-ico}/ico-lm-properties';
@svg-lm-infopages      : '@{path-ico}/ico-lm-infopages';
@svg-lm-blog           : '@{path-ico}/ico-lm-blog';
@svg-lm-tags           : '@{path-ico}/ico-lm-tags';
@svg-lm-erp-users      : '@{path-ico}/ico-lm-erp-users';
@svg-lm-logout         : '@{path-ico}/ico-lm-logout';
@svg-lm-money          : '@{path-ico}/ico-lm-money';
@svg-chevron-up        : '@{path-ico}/chevron-up';

@svg-left-arrow2       : '@{path-ico}/left-arrow2';
@svg-right-arrow2      : '@{path-ico}/right-arrow2';
@svg-down-arrow        : '@{path-ico}/dropdown_arrow';
@svg-down-arrow2       : '@{path-ico}/down-arrow2';

@svg-checkbox_0        : '@{path-ico}/checkbox_0';
@svg-checkbox_1        : '@{path-ico}/checkbox_1';

//@svg-play              : '@{path-ico}/c2/play';
@svg-play              : '@{path-ico}/playbtn';
//@svg-stop              : '@{path-ico}/c2/stop';
@svg-stop              : '@{path-ico}/stopbtn';
//@svg-pause             : '@{path-ico}/c2/pause';
@svg-pause             : '@{path-ico}/pausebtn';
@svg-eye               : '@{path-ico}/eye';
@svg-mail              : '@{path-ico}/mail';

@svg-download          : '@{path-ico}/c2/download';
@svg-upload            : '@{path-ico}/c2/upload';
@svg-login             : '@{path-ico}/c2/login';
@svg-logout            : '@{path-ico}/c2/logout';
@svg-cloud-download    : '@{path-ico}/c2/cloud-download';
@svg-cloud-upload      : '@{path-ico}/c2/cloud-upload';
@svg-down-arrow2       : '@{path-ico}/c2/down-arrow2';
@svg-copy              : '@{path-ico}/copy';
@svg-clock             : '@{path-ico}/clock';
@svg-calendar          : '@{path-ico}/calendar';
@svg-pointer-click     : '@{path-ico}/pointer-click';
@svg-empty-window      : '@{path-ico}/empty-window';

@svg-star              : '@{path-ico}/star';
@svg-star-active       : '@{path-ico}/star-active';
@svg-star-half         : '@{path-ico}/star-half';

@svg-dislike           : '@{path-ico}/dislike';
@svg-like              : '@{path-ico}/like';


@shadow                : 10px 10px 5px -3px rgba(0, 0, 0, .03);
@shadow-hover          : 10px 14px 5px -3px rgba(0, 0, 0, .06);
@shadow2               : 0px 10px 44px 0px rgba(0, 0, 0, .28);
@shadow3               : 0px 4px 20px rgba(0, 0, 0, 0.2);
@shadow4               : 0px 4px 6px rgba(0, 0, 0, .4);

/*===================================================*/

@desktop-big                      : 1921px;
@desktop                          : 1600px;
@desktop-small                    : 1366px;
@tablet-big                       : 998px;
@tablet                           : 768px;
@tablet-mid                       : 680px;
@tablet-small                     : 640px;
@phone-big                        : 480px;
@phone                            : 412px;
@phone-small                      : 360px;

/*===================================================*/

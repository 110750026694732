@import '../common/variables';

.dropdown {
  position        : absolute;
  width           : 100%;
  margin-top      : 2px;
  background      : rgba(0, 0, 0, .01);
  z-index         : 9999;
  top             : 100%;
  left            : 0;


  color           : #000000;

  overflow        : auto;
  scrollbar-width : thin;
  background      : @white;
  //box-shadow      : @shadow;
  box-shadow      : 0px 15px 15px 0px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    display          : block;
    width            : 6px;
    border-radius    : 3px;
    background-color : @white;
  }
  &::-webkit-scrollbar-thumb {
    width            : 6px;
    border-radius    : 3px;
    background-color : @dark-gray;
  }
  &::-webkit-scrollbar-button {
    //display: none;
  }

  .dropdown-row {
    position      : relative;
    display       : flex;
    flex-flow     : row wrap;
    align-items   : center;
    width         : 100%;
    height        : 28px;
    //border-radius : 6px;
    margin-bottom : 1px;
    background    : #FFFFFF;
    border-bottom : 1px solid @lite-gray;
    cursor        : pointer;
    font-size     : 14px;
    &.double-h {
      height : 61px;
    }
    &.triple-h {
      height : 89px;
    }
    &:last-child {
      border-bottom : none;
    }
    &:hover {
      background : #D1D8E2;
    }
    .row-texts {
      position      : relative;
      display       : flex;
      flex-flow     : row wrap;
      width         : 100%;
      height        : auto;
      border-radius : 6px;
    }
    .row-item {
      padding : 5px 5px;
      &:first-child {
        padding-left : 10px;
      }
      &:last-child {
        padding-right : 10px;
      }
    }
  }

  .grid {
    //max-height      : 145px;
    overflow        : auto;
    scrollbar-width : thin;
    background      : @white;
    box-shadow      : @shadow;
    &::-webkit-scrollbar {
      display          : block;
      width            : 6px;
      border-radius    : 3px;
      background-color : @white;
    }
    &::-webkit-scrollbar-thumb {
      width            : 6px;
      border-radius    : 3px;
      background-color : @dark-gray;
    }
    &::-webkit-scrollbar-button {
      //display: none;
    }
  }
}
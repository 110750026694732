@import '../common/variables';
@import '../common/mixins';

.items-list-holder {
  position : relative;
  width    : 100%;
  //padding  : 0 25px;
  
  .items-list-element {
    position         : relative;
    display          : flex;
    flex-flow        : row wrap;
    width            : fit-content;
    min-width        : 100%;
    height           : auto;
    min-height       : 40px;
    margin-bottom    : 4px;
    //padding          : 5px 5px;
    //overflow      : hidden;
    //box-shadow       : 10px 10px 5px -3px rgba(0, 0, 0, 0.1);
    border-radius    : 6px;
    background-color : #ffffff;
    box-shadow       : @shadow;
    transition       : background-color .5s ease;
    
    &:last-child {
      margin-bottom : 0;
    }
    
    &.hover {
      background-color : #eeeeee;
    }
    &.red {
      background-color : #ffaaaa !important;
    }
    &.red1, &red-1 {
      background-color : #f8cfcf;
      
      &.hover {
        background-color : #f0acac;
      }
    }
    &.aqua {
      background-color : #cff7f8;
      
      &.hover {
        background-color : #99e6e8;
      }
    }
    &.green1, &.green-1 {
      background-color : #d2ffd6;
      
      &.hover {
        background-color : #adf2b3;
      }
    }
    &.gray-1 {
      background-color : #eeeeee;
      /* &.hover {
         background-color : #ADF2B3;
       }*/
    }
    &.gray-2 {
      background-color : #aaaaaa;
      /* &.hover {
         background-color : #ADF2B3;
       }*/
    }
    
    &.hidden {
      height : 66px;
    }
    
    > .cells {
      position      : relative;
      display       : flex;
      flex-flow     : row nowrap;
      //padding    : 5px 5px;
      width         : 100%;
      min-height    : 40px;
      //background-color : #FFFFFF;
      border-radius : 6px;
      
      .resp(@tablet-big, {
        flex-flow       : column nowrap;
        justify-content : flex-start;
        align-items     : stretch;
        
        height          : fit-content;
        padding-left    : 10px;
      });
    }
    
    .cell {
      position                  : relative;
      padding                   : 11px 15px;
      //padding-left    : 15px;
      //padding-right   : 15px;
      //height          : 100%;
      border-top-left-radius    : 8px;
      border-bottom-left-radius : 8px;
      border-right              : 1px solid #f6f6f6;
      min-width                 : 130px;
      
      flex                      : 0 0 10%;
      
      display                   : flex;
      justify-content           : center;
      align-items               : center;
      
      font-size                 : 14px;
      font-weight               : 400;
      color                     : #000000;
      text-align                : center;
      //cursor          : pointer;
      .resp(@tablet-big, {
        justify-content : flex-start;
        
        padding         : 4px 0 4px 10px;
        text-align      : left;
        min-height      : 30px;
        border-right    : none;
        border-top      : 1px solid #f6f6f6;
        border-bottom   : 1px solid #f6f6f6;
      });
      
      .mobile-cell-title {
        display : none;
        .resp(@tablet-big, {
          display      : inline;
          margin-right : 15px;
        });
      }
      
      &.half {
        min-width : 80px;
        
        flex      : 0 0 5%;
      }
      &.left {
        text-align      : left;
        justify-content : flex-start;
      }
      &.center {
        text-align      : center;
        justify-content : center;
      }
      &:last-child {
        border-right : none;
        .resp(@tablet-big, {
          border-bottom : none;
          margin-bottom : 10px;
        });
      }
      &.text-center {
        text-align : center;
      }
      &.text {
        cursor : text;
      }
      &.pointer {
        cursor : pointer;
      }
      &.grow {
        flex-grow       : 1;
        justify-content : flex-start;
        text-align      : left;
        
        &.center {
          text-align      : center;
          justify-content : center;
        }
      }
      &.shrink {
        flex-shrink : 1;
        flex-basis  : 5%;
        padding     : 0;
        min-width   : auto;
      }
      &.icon-upload {
        background : url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Crect x='111.296' y='262.421' width='137.918' height='31.345'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Crect x='110.314' y='135.578' width='137.918' height='31.345'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Crect x='110.314' y='199.313' width='250.761' height='31.345'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpolygon points='374.189,377.268 374.189,337.125 342.844,337.125 342.844,377.268 304.703,377.268 304.703,408.613 342.844,408.613 342.844,448.757 374.189,448.757 374.189,408.613 412.328,408.613 412.328,377.268 '/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath d='M434.385,304.812V106.726L328.227,0H36.68v511.97h318.296c0.802,0.016,1.605,0.03,2.411,0.03 c65.027,0,117.932-52.904,117.932-117.932C475.32,358.44,459.439,326.454,434.385,304.812z M329.206,45.431l51.812,52.09h-51.812 V45.431z M68.026,480.625V31.345h229.836v97.521H403.04v156.467c-14.049-5.92-29.474-9.197-45.651-9.197 c-65.027,0-117.932,52.904-117.932,117.932c0,34.177,14.618,64.998,37.923,86.556H68.026z M358.595,480.625h-2.414 c-47.188-0.651-85.38-39.217-85.38-86.556c0-47.744,38.843-86.587,86.587-86.587c47.744,0,86.587,38.843,86.587,86.587 C443.975,441.408,405.782,479.974,358.595,480.625z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center / contain;
      }
      &.error {
        border        : 1px red solid;
        border-radius : 8px;
      }
      &.marked {
        &:before {
          content       : '';
          position      : relative;
          display       : inline-block;
          width         : 8px;
          height        : 8px;
          border-radius : 50%;
          margin-right  : 10px;
          flex-shrink   : 0;
        }
      }
      &.w5percent {
        flex : 0 0 5%;
      }
      &.w10percent {
        flex : 0 0 10%;
      }
      &.w15percent {
        flex : 0 0 15%;
      }
      &.w20percent {
        flex : 0 0 20%;
      }
      &.color-green {
        color : @mate-green;
        
        &.marked {
          &:before {
            background-color : @mate-green;
          }
        }
      }
      &.color-red {
        color : @mate-red;
        
        &.marked {
          &:before {
            background-color : @mate-red;
          }
        }
      }
      &.color-yellow {
        color : @mate-yellow;
        
        &.marked {
          &:before {
            background-color : @mate-yellow;
          }
        }
      }
      &.color-blue {
        color : @mate-blue;
        
        &.marked {
          &:before {
            background-color : @mate-blue;
          }
        }
      }
      &.cell-dropdown {
        text-align : center;
        .resp(@tablet-big, {
          align-self    : flex-start;
          
          padding-right : 40px;
        });
        
        &:after {
          content             : '';
          display             : block;
          position            : absolute;
          width               : 25px;
          height              : 100%;
          right               : 0;
          top                 : 0;
          border-left         : 1px @main-bg solid;
          .svg(@svg-down-arrow, @dark-blue);
          background-size     : 50%;
          background-position : center;
          background-repeat   : no-repeat;
        }
      }
      
      a {
        color           : black;
        text-decoration : none;
      }
      input {
        position    : relative;
        width       : 100%;
        height      : 100%;
        font-family : @font;
        border      : none;
        font-size   : 14px;
        font-weight : 500;
        color       : #898989;
        background  : none;
        transition  : background-color .5s ease;
        
        &:focus {
          outline : none;
        }
        
        &[type='file'] {
          &::-webkit-file-upload-button { /* chromes and blink button */
            cursor : pointer;
          }
          
          opacity : 0;
          cursor  : pointer !important;
        }
        
        &.error {
        
        }
      }
      textarea {
        position   : relative;
        width      : 100%;
        background : none;
        border     : none;
        outline    : none;
        resize     : none;
      }
      span {
        display : block;
        
        &.f-10 {
          font-size : 10px;
        }
      }
      
      &.button {
        min-width : 120px;
      }
      &.cell-controls-list {
        display : flex;
        /*& > div {
          display : flex;
        }*/
        
        div {
          position      : relative;
          width         : 30px;
          height        : 30px;
          font-size     : 0;
          border        : 1px solid @lite-gray;
          border-radius : 6px;
          margin        : 0 3px;
          cursor        : pointer;
          
          &:after {
            content          : '';
            position         : absolute;
            top              : 0;
            left             : 0;
            width            : 100%;
            height           : 100%;
            border-radius    : 6px;
            opacity          : 0;
            .svg(@svg-play, @white);
            background-color : @mate-blue;
            transition       : opacity .3s ease-in-out;
            pointer-events   : none;
          }
          
          &:hover {
            border : none;
            
            &:after {
              opacity : 1;
            }
          }
          
          &.copy {
            .svg(@svg-copy, @dark-blue);
            background-size     : 50%;
            background-position : center;
            
            &:after {
              .svg(@svg-copy, @white);
              background-color    : @mate-blue;
              background-size     : 50%;
              background-position : center;
            }
          }
          
          &.start {
            .svg(@svg-play, @mate-blue);
            //background-size: 175%;
            //background-position: center;
            &:after {
              .svg(@svg-play, @white);
              background-color : @mate-blue;
            }
          }
          
          &.stop {
            .svg(@svg-stop, @dark-gray);
            //background-size: 175%;
            //background-position: center;
            &:after {
              .svg(@svg-stop, @white);
              background-color : @mate-blue;
            }
          }
          
          &.pause {
            .svg(@svg-pause, @mate-red);
            //background-size: 175%;
            //background-position: center;
            &:after {
              .svg(@svg-pause, @white);
              background-color : @mate-blue;
            }
          }
          
          &.play {
            .svg(@svg-play, @mate-blue);
            //background-size: 175%;
            //background-position: center;
            &:after {
              .svg(@svg-play, @white);
              background-color : @mate-blue;
            }
          }
          
          &.upload {
            .svg(@svg-upload, @dark-blue);
            background-size     : 175%;
            background-position : center;
            
            &:after {
              .svg(@svg-upload, @white);
              background-color    : @mate-blue;
              background-size     : 175%;
              background-position : center;
            }
          }
          
          &.uploaded {
            border     : none;
            background : none;
            
            &:after {
              display : none;
            }
            
            &:hover {
              &:after {
                display : none;
              }
            }
          }
          
          &.eye {
            .svg_stroke(@svg-eye, @mate-blue);
            background-size     : 65%;
            background-position : center;
            
            &:after {
              .svg_stroke(@svg-eye, @white);
              background-color    : @mate-blue;
              background-size     : 70%;
              background-position : center;
            }
          }
          
          &.mail {
            .svg_stroke(@svg-mail, @mate-blue);
            background-size     : 65%;
            background-position : center;
            
            &:after {
              .svg_stroke(@svg-mail, @white);
              background-color    : @mate-blue;
              background-size     : 70%;
              background-position : center;
            }
          }
        }
        
      }
      
      .input {
        width : 100%;
        
        .search {
          width  : 100%;
          margin : 0;
          
          input {
            box-shadow : none;
          }
        }
      }
      .button {
        display          : flex;
        border-radius    : 8px;
        height           : calc(100% - 10px);
        max-height       : 30px;
        justify-content  : center;
        align-items      : center;
        width            : 100%;
        background-color : @white;
        color            : @dark-blue;
        border           : 1px solid @lite-gray;
        cursor           : pointer;
        transition       : background-color .5s ease-in-out, color .5s ease-in-out;
        
        font-size        : 14px;
        
        &:hover {
          background-color : @base-blue-hover;
          color            : @white;
        }
        
        &.red {
          color : @white;
          
          &:hover {
            color      : @mate-red;
            background : @white !important;
            border     : 1px solid @mate-red;
          }
        }
        
        &.green {
          //color            : #575974;
          color : @white;
          
          &:hover {
            color      : @mate-green;
            background : @white !important;
            border     : 1px solid @mate-green;
          }
        }
      }
      .icon {
        display       : block;
        //align-items     : center;
        //justify-content : center;
        //min-width       : 50px;
        position      : relative;
        width         : 30px;
        height        : 30px;
        font-size     : 0;
        border        : 1px solid @dark-blue;
        border-radius : 6px;
        cursor        : pointer;
        
        &.play_btn {
          .svg(@svg-play, @dark-blue);
          background-size     : contain;
          background-position : center;
          background-repeat   : no-repeat;
          
          //svg {
          //  display  : block;
          //  position : relative;
          //  height   : 35px;
          //}
        }
        
        &.icon-upload {
          //position   : relative;
          //height     : 100%;
          //background : url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Crect x='111.296' y='262.421' width='137.918' height='31.345'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Crect x='110.314' y='135.578' width='137.918' height='31.345'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Crect x='110.314' y='199.313' width='250.761' height='31.345'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpolygon points='374.189,377.268 374.189,337.125 342.844,337.125 342.844,377.268 304.703,377.268 304.703,408.613 342.844,408.613 342.844,448.757 374.189,448.757 374.189,408.613 412.328,408.613 412.328,377.268 '/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath d='M434.385,304.812V106.726L328.227,0H36.68v511.97h318.296c0.802,0.016,1.605,0.03,2.411,0.03 c65.027,0,117.932-52.904,117.932-117.932C475.32,358.44,459.439,326.454,434.385,304.812z M329.206,45.431l51.812,52.09h-51.812 V45.431z M68.026,480.625V31.345h229.836v97.521H403.04v156.467c-14.049-5.92-29.474-9.197-45.651-9.197 c-65.027,0-117.932,52.904-117.932,117.932c0,34.177,14.618,64.998,37.923,86.556H68.026z M358.595,480.625h-2.414 c-47.188-0.651-85.38-39.217-85.38-86.556c0-47.744,38.843-86.587,86.587-86.587c47.744,0,86.587,38.843,86.587,86.587 C443.975,441.408,405.782,479.974,358.595,480.625z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center / contain;
          .svg(@svg-upload, @dark-blue);
          background-size     : contain;
          background-position : center;
          background-repeat   : no-repeat;
        }
      }
      .file-input {
        display         : flex;
        align-items     : center;
        justify-content : center;
        padding         : 6px;
        
        .upload {
          &:after {
            content          : '';
            position         : absolute;
            top              : 0;
            left             : 0;
            width            : 100%;
            height           : 100%;
            border-radius    : 6px;
            opacity          : 0;
            .svg(@svg-play, @white);
            background-color : @mate-blue;
            transition       : opacity .3s ease-in-out;
            pointer-events   : none;
          }
          
          &:hover {
            border : none;
            
            &:after {
              opacity : 1;
            }
          }
          
          position            : relative;
          width               : 30px;
          height              : 30px;
          font-size           : 0;
          border              : 1px solid #d8d8d8;
          border-radius       : 6px;
          margin              : 0 3px;
          cursor              : pointer;
          .svg(@svg-upload, @dark-blue);
          background-size     : 175%;
          background-position : center;
          
          &:after {
            .svg(@svg-upload, @white);
            background-color    : @mate-blue;
            background-size     : 175%;
            background-position : center;
          }
        }
        
        .unselected {
          padding : 0;
        }
      }
      .image {
        position      : relative;
        width         : 80px;
        height        : 80px;
        border-radius : 6px;
        border        : 1px solid @dark-gray;
        .svg(@svg-upload, @dark-gray);
      }
    }
    
    .dd-btn {
      position        : relative;
      background      : url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M12.5906 9.98913C12.3951 10.1846 12.0791 10.1846 11.8836 9.98913L6.73716 4.84274L1.59078 9.98913C1.39529 10.1846 1.07929 10.1846 0.883799 9.98913C0.688311 9.79364 0.688311 9.47764 0.883799 9.28215L6.38369 3.78226C6.48119 3.68476 6.60918 3.63576 6.73719 3.63576C6.8652 3.63576 6.99319 3.68476 7.09069 3.78226L12.5906 9.28215C12.786 9.47764 12.786 9.79364 12.5906 9.98913Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='12' height='12' fill='white' transform='matrix(1 0 0 -1 0.737183 12.8857)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center;
      //background      : url("/img/down_arrow.svg") no-repeat center;
      border-right    : 1px solid #f6f6f6;
      cursor          : pointer;
      padding         : 10px 20px;
      transform       : rotate(180deg);
      
      //flex            : 0 0 5%;
      
      display         : flex;
      justify-content : center;
      align-items     : center;
      
      font-size       : 16px;
      font-weight     : bold;
      
      .resp(@tablet-big, {
        align-self   : flex-end;
        
        min-height   : 30px;
      });
      
      &.open {
        transform    : rotate(0deg);
        border-left  : 1px solid #f6f6f6;
        border-right : none;
      }
    }
    .dummy-dd-btn {
      position : relative;
      //flex     : 0 0 5%;
    }
    .color {
      position   : absolute;
      right      : 0;
      width      : 8px;
      height     : 100%;
      background : none;
      
      &.red {
        background : #ff0000;
      }
      
      &.green {
        background : #1bc236;
      }
      
      &.yellow {
        background : #ffb800;
      }
    }
    .inner-holder {
      position      : relative;
      width         : 100%;
      //max-height    : 1000px;
      //overflow   : hidden;
      transition    : max-height ease-in-out .1s;
      background    : #e6e8eb;
      padding       : 10px;
      border-radius : 6px;
      border        : 1px solid #dadbdc;
      border-top    : none;
      
      flex-basis    : 100%;
      
      &.hidden {
        max-height : 0;
        padding    : 0 10px;
      }
      
      .page-content {
        padding : 0;
      }
    }
  }
  
  &.text-string-in {
    .items-list-element {
      .cell {
        padding     : 10px 15px;
        align-items : flex-start;
        
        text-align  : left;
        line-height : 28px;
        word-break  : break-all;
        
        &.grow {
          padding-top : 15px;
          line-height : 1.2;
        }
        
        .button {
          margin-top : 15px;
          margin     : 0;
          height     : 28px;
        }
      }
    }
  }
  
  .holder {
    position  : relative;
    padding   : 0px 25px 20px;
    display   : flex;
    flex-flow : row nowrap;
    
    .left {
      position      : relative;
      padding-right : 20px;
      width         : 316px;
      border-right  : 1px solid #d1d8e2;
      box-sizing    : content-box;
      
      .infoline-holder {
        position   : relative;
        width      : 100%;
        max-height : 800px;
        transition : max-height ease-in-out .1s;
        overflow   : hidden;
        
        &.hidden {
          max-height : 0;
        }
        
        .infoline {
          position      : relative;
          width         : 100%;
          padding       : 9px 0;
          border-bottom : 1px solid #d1d8e2;
          
          display       : flex;
          flex-flow     : row nowrap;
          align-items   : flex-start;
          
          font-size     : 13px;
          color         : #222222;
          
          .title {
            position      : relative;
            padding-right : 5px;
            
            flex-basis    : 45%;
            
            font-weight   : bold;
          }
          
          .info {
            position     : relative;
            padding-left : 5px;
            
            flex-basis   : 55%;
          }
        }
      }
      
      .left-bottom {
        position   : relative;
        width      : 100%;
        margin-top : 24px;
        
        display    : flex;
        
        .infoline-open-btn {
          position        : relative;
          height          : 40px;
          background      : #d1d8e2;
          border-radius   : 26px;
          cursor          : pointer;
          
          flex-basis      : 50%;
          display         : flex;
          justify-content : center;
          align-items     : center;
          
          font-size       : 13px;
          color           : #43525a;
        }
      }
    }
    
    .right {
      position     : relative;
      padding-left : 20px;
      
      flex-grow    : 1;
    }
    
    .center {
      flex-basis : 100%;
    }
  }
  .empty-text {
    padding    : 0;
    text-align : center;
    margin     : 35px 0;
    color      : #444444;
  }
  
  &.no-header-buttons {
    .buttons-holder {
      display : none;
    }
  }
  &.align-left {
    .table-row .innerHolder {
      padding       : 0 10px 0 0;
      border-left   : none;
      border-bottom : none;
    }
  }
  &.align-right {
    .table-row .innerHolder {
      padding       : 0 0 0 10px;
      border-right  : none;
      border-bottom : none;
    }
  }
}

.cross {
  padding         : 10px 20px;
  background      : url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.6195 0.258548C15.3684 0.0891683 15.0349 0.056065 14.7577 0.178548C14.55 0.266272 14.3976 0.442272 14.2408 0.600616C12.2178 2.658 10.1953 4.71648 8.17167 6.77386C6.14699 4.71648 4.12448 2.65689 2.10035 0.598961C1.93655 0.431789 1.77709 0.2431 1.55038 0.164755C1.19784 0.0224099 0.767735 0.139927 0.526379 0.436203C0.293159 0.708203 0.240008 1.12144 0.396212 1.44586C0.482991 1.64172 0.648957 1.78075 0.793229 1.93137C2.81628 3.98931 4.83987 6.04669 6.86238 8.10572C4.82143 10.1835 2.77885 12.2597 0.737363 14.3369C0.447193 14.5747 0.239464 14.957 0.323532 15.3438C0.403261 15.8376 0.919601 16.1973 1.40123 16.0897C1.67947 16.045 1.88828 15.837 2.07485 15.639C4.10713 13.5716 6.13885 11.5043 8.17167 9.43758C10.269 11.5683 12.3626 13.7024 14.4605 15.8326C14.7951 16.183 15.3966 16.1979 15.7416 15.8552C16.0247 15.5959 16.1174 15.1496 15.9607 14.7959C15.8777 14.5874 15.7047 14.4395 15.5534 14.2834C13.5292 12.2243 11.5046 10.1659 9.48096 8.10572C11.5219 6.02737 13.565 3.95124 15.6065 1.874C15.8175 1.69248 15.9981 1.44751 16.029 1.16062C16.0768 0.812478 15.9097 0.448341 15.6195 0.258548Z' fill='%23929292'/%3E%3C/svg%3E%0A") no-repeat center;
  cursor          : pointer;
  //border-right    : 1px solid #F6F6F6;
  
  display         : flex;
  //flex            : 0 0 5%;
  justify-content : center;
  align-items     : center;
  
  font-size       : 16px;
  font-weight     : bold;
}
.checkbox {
  padding         : 10px 20px;
  cursor          : pointer;
  border-right    : 1px solid #f6f6f6;
  
  display         : flex;
  //flex            : 0 0 5%;
  justify-content : center;
  align-items     : center;
  
  font-size       : 16px;
  font-weight     : bold;
  
  input {
    position  : absolute;
    transform : scale(1.3);
  }
}

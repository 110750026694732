.marker {
  display       : inline-block;
  width         : 8px;
  height        : 8px;
  border-radius : 50%;
  margin-right  : 10px;
  flex-shrink   : 0;

  &.color-green {
    background-color : @mate-green;
  }
  &.color-red {
    background-color : @mate-red;
  }
  &.color-yellow {
    background-color : @mate-yellow;
  }
  &.color-blue {
    background-color : @mate-blue;
  }
}
@import '../common/variables';
@import '../common/mixins';

.rating {
  padding            : 0;
  list-style-type    : none;
  display            : flex;
  flex               : 0 0 auto;
  margin-block-start : 0;
  margin-block-end   : 0;
  &.rating-big {
    > li {
      width  : 26px;
      height : 26px;
      & + li {
        margin-left : 10px;
      }
    }
  }
  > li {
    flex            : 0 0 auto;
    width           : 15px;
    height          : 15px;
    //background      : url('@{path-ico}/star.svg') no-repeat;
    .svg(@svg-star, @base-blue);
    background-size : 100% 100%;
    & + li {
      margin-left : 6px;
    }
  }
  .rated-half {
    //background-image : url('@{path-ico}/star-half.svg');
    .svg(@svg-star-half, @base-blue);
    background-size : 100% 100%;
  }
  .rated {
    //background-image : url('@{path-ico}/star-active.svg');
    .svg(@svg-star-active, @base-blue);
    background-size : 100% 100%;
  }
  &.clickable {
    > li {
      cursor : pointer;
      &:hover {
        //background-image : url('@{path-ico}/star-active.svg');
        .svg(@svg-star-active, @base-blue);
        background-size : 100% 100%;
      }
    }
  }
}
.review-type {
  flex            : 0 0 auto;

  display         : flex;
  justify-content : flex-end;
  gap             : 3px;

  position        : relative;
  padding-left    : 20px;

  font-size       : 14px;
  &.is-negative {
    color : @mate-red;
    &:before {
      //background      : url('@{path-ico}/dislike.svg') no-repeat;
      .svg(@svg-dislike, @mate-red);
      background-size : 100% 100%;
      top             : 3px;
    }
  }
  &.is-positive {
    color : @mate-green;
    &:before {
      //background      : url('@{path-ico}/like.svg') no-repeat;
      .svg(@svg-like, @mate-green);
      background-size : 100% 100%;
      top             : 1px;
    }
  }
  &:before {
    position : relative;
    //left     : 0;
    width    : 16px;
    height   : 12px;
    content  : '';
  }
}


@import '../common/variables';
@import '../common/mixins';

.filter-dropdown {
  position  : relative;
  min-width : 150px;
  max-width : 200px;
  
  .resp(@tablet-big, {
    min-width : 200px;
  });
  
  .title {
    position      : relative;
    margin-bottom : 5px;
    font-size     : 12px;
  }
  .text {
    position         : relative;
    display          : flex;

    //margin-left      : 15px;
    border-radius    : 6px;
    padding          : 0 51px 0 10px;
    height           : 30px;
    justify-content  : start;
    align-items      : center;
    min-width        : 150px;
    background-color : @white;
    color            : @dark-blue;
    cursor           : pointer;
    transition       : background-color .5s ease;
    box-shadow       : @shadow;

    font-size        : 14px;
    font-weight      : 400;

    &:after {
      content             : '';
      display             : block;
      position            : absolute;
      width               : 25px;
      height              : 100%;
      right               : 0;
      top                 : 0;
      border-left         : 1px @main-bg solid;
      .svg(@svg-down-arrow, @dark-blue);
      background-size     : 50%;
      background-position : center;
      background-repeat   : no-repeat;
    }

    &:hover {
      //background-color : @base-blue-hover;
    }
  }
}

@import '../common/variables';

.info-content {
  position  : relative;
  //padding  : 0 20px;
  margin    : 0 -10px;

  display   : flex;
  flex-flow : row wrap;

  //display               : grid;
  //grid-auto-flow        : row dense;
  //grid-auto-columns     : minmax(80px, 95%);
  //grid-auto-columns : min-content;
  //grid-auto-rows    : max-content;
  //grid-template-columns : auto auto auto auto auto auto auto auto auto auto auto auto;
  //grid-auto-rows        : auto auto auto auto auto auto auto auto auto auto auto auto;

  //.row {
  //  margin : 0 -10px;
  //}
  .info-element {
    margin : 0 10px 15px;
  }
}

.info-element {
  position      : relative;
  border-radius : 6px;
  background    : #F3F3F3;
  color         : #3C3C3C;
  box-shadow    : @shadow;

  font-size     : 16px;
  //overflow      : hidden;

  align-self    : start;

  &.textarea {
    min-width : calc(100% - 20px);
    .ck.ck-editor {
      width : 100%;
    }
  }
  &.image-input {
    height : 100px;

    .image-preview {
      position    : relative;
      margin-left : 10px;
      width       : 75px;
      height      : 75px;
    }
  }
  &.info-dropdown {
    .text {
      background    : @white;
      padding-right : 35px;
      //color      : @dark-blue;
      &:after {
        content             : '';
        display             : block;
        position            : absolute;
        width               : 25px;
        height              : 100%;
        right               : 0;
        top                 : 0;
        border-left         : 1px @main-bg solid;
        .svg(@svg-down-arrow, @dark-blue);
        background-size     : 50%;
        background-position : center;
        background-repeat   : no-repeat;
      }
    }
  }

  &.long {
    min-width : 240px;
    .input {
      input {
        min-width : 240px;
      }
    }
  }
  &.low {
    .title {
      padding : 5px 10px;
    }
    .input {
      height  : 32px;
      padding : 0 5px;
      input {
        font-size : 16px;
      }
    }
  }

  .double-row {
    //grid-row : 1 / span 2;
  }
  .title {
    position    : relative;
    padding     : 10px;

    font-size   : 13px;
    font-weight : 500;
    &.hint-icon {
      padding-right : 32px;
      .icon {
        content         : '?';
        position        : absolute;
        display         : inline-flex;
        right           : 10px;
        top             : 11px;
        width           : 12px;
        height          : 12px;
        border-radius   : 50%;
        border          : 1px solid @dark-gray;
        justify-content : center;
        align-items     : center;
        font-size       : 8px;
      }
    }
    .cross {
      position : absolute;
      right    : 10px;
      margin   : auto 0;
      top      : 0;
      bottom   : 0;
    }
  }
  .text {
    position        : relative;
    height          : 42px;
    padding         : 0 10px;
    background      : #CECDCD;
    //border          : 1px solid #898989;
    border-radius   : 6px;
    min-width       : 100%;

    display         : flex;
    justify-content : center;
    align-items     : center;

    color           : #252631;
    input {
      outline    : none;
      border     : none;
      background : none;
      font-size  : 16px;
      &:focus {
        outline : none;
      }
      &::placeholder {
        color : #AAAAAA;
      }
    }
  }
  .input {
    position        : relative;
    height          : 42px;
    padding         : 0 10px;
    background      : #FFFFFF;
    //border          : 1px solid #898989;
    border-radius   : 6px;
    min-width       : 100%;

    display         : flex;
    justify-content : start;
    align-items     : center;

    color           : #252631;
    input {
      outline    : none;
      border     : none;
      background : none;
      min-width  : 100%;

      font-size  : 16px;
      &:focus {
        outline : none;
      }
      &::placeholder {
        color : #AAAAAA;
      }
      &.short {
        max-width : 80px;
      }
      &.medium {
        max-width : 160px;
      }
      &.long {
        max-width : 240px;
      }
    }
    .search {
      margin : 0;
      input {
        padding       : 0;
        border-radius : 0;
        height        : auto;
        box-shadow    : none;
      }
    }
  }
  .textarea {
    position        : relative;
    min-width       : 100%;
    height          : auto;
    padding         : 5px;
    background      : #FFFFFF;
    //border          : 1px solid #898989;
    border-radius   : 6px;
    min-width       : 100%;

    display         : flex;
    justify-content : center;
    align-items     : center;

    color           : #252631;

    textarea {
      position   : relative;
      height     : 280px;
      width      : 100%;
      border     : none;
      outline    : none;
      background : none;
      resize     : vertical;
      font-size  : 16px;
      &:focus {
        border  : none;
        outline : none;
      }
      &::placeholder {
        color : #AAAAAA;
      }
    }
  }
  a:not(.ck-editor a) {
    display         : inline-flex;
    min-width       : 80px;
    //height          : 100%;
    padding         : 10px;
    background      : #1E559F;
    border          : 1px solid #898989;
    border-radius   : 6px;

    justify-content : center;
    //align-items     : center;

    color           : #E2E2E2;
    text-decoration : none;
    text-transform  : uppercase;

    transition      : color .3s ease-in-out, background-color .3s ease-in-out;
    &:hover {
      background : #266DCC;
      color      : #FFFFFF;
    }
    &.video-show-btn {
      display       : block;
      background    : none;
      min-width     : auto;
      width         : 30px;
      height        : 30px;
      border-radius : 99px;

      .svg(@svg-play, @mate-blue);

    }
  }
  .status {
    position        : relative;
    height          : 42px;
    //padding         : 0 10px;
    //border          : 1px solid #898989;
    width           : 100%;

    display         : flex;
    justify-content : center;
    align-items     : center;

    color           : #FFFFFF;
    text-transform  : uppercase;
    text-align      : center;
    .button {
      position      : relative;
      width         : 100%;
      height        : 100%;
      border-radius : 6px;
      margin        : 0;
      &.green {
        background : #2C863A;
      }
      &.red {
        background : #832B2B;
      }
      &.white {
        background : #FFFFFF;
        color      : #252631;
      }
    }
  }
  .info-bubble {
    position       : absolute;
    right          : 0;
    bottom         : 0;
    border-radius  : 6px;
    color          : #2C863A;
    padding        : 15px;
    width          : 25%;
    height         : 12px;

    font-size      : 10px;
    text-align     : right;
    text-transform : uppercase;
  }
  .buttons-list {
    position        : relative;
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-between;
    padding         : 5px 0;
    background      : #FFFFFF;
    //border          : 1px solid #898989;
    border-radius   : 6px;
    .button {
      display          : flex;

      width            : auto;
      margin           : 0 0 0 5px;
      border-radius    : 8px;
      padding          : 0 10px;
      height           : 30px;
      justify-content  : center;
      align-items      : center;
      //min-width        : 100px;
      background-color : #FFFFFF;
      border           : 1px solid @lite-lite-gray;

      color            : #252631;
      cursor           : pointer;
      transition       : background-color .5s ease-in-out, color .5s ease-in-out;

      font-size        : 14px;
      font-weight      : 400;

      &:last-child {
        margin-right : 5px;
      }
      &.selected {
        background-color : @base-blue;
        color            : #FFFFFF;
        border           : 1px solid @base-blue;
      }
      &:hover {
        background-color : @base-blue-hover;
        color            : #FFFFFF;
      }
    }
  }
  .info-block {
    position      : absolute;
    width         : 100%;
    height        : 100%;
    top           : 0;
    left          : 0;
    background    : @dark-blue;
    opacity       : .05;
    border-radius : 6px;
  }
  .rating {
    padding       : 10px;
    background    : @white;
    border-radius : 6px;
  }
  .audio-holder {
    border-radius : 6px;
    overflow      : hidden;
  }
  .radio {
    position        : relative;
    //height          : 42px;
    padding         : 0 10px;
    background      : #FFFFFF;
    //border          : 1px solid #898989;
    border-radius   : 6px;

    display         : flex;
    flex-flow       : column;
    justify-content : start;
    align-items     : start;

    color           : #252631;
    .radio-line {
      padding : 5px 0;
    }
    input {
      cursor     : pointer;
      outline    : none;
      border     : none;
      background : none;
      font-size  : 16px;
      &:focus {
        outline : none;
      }
      &::placeholder {
        color : #AAAAAA;
      }
    }
    label {
      cursor : pointer;
    }
  }

  .controls-holder {
    background    : #FFFFFF;
    border-radius : 6px;
    width         : 100%;
    .image-holder {
      //margin-right : 15px;
      margin : 2px 15px;
      height : 52px;
      width  : 52px;
      cursor : pointer;
    }
  }
}

.select, .select-exist {
  position : relative;
  .search {
    margin : 0 !important;
  }
}
.select {
  position : absolute;
  top      : 100%;
  left     : 0;
  width    : 100%;
  .dropdown {
    min-width   : 150px;
    font-weight : 300;
  }
}
.select-line {
  display   : flex;
  flex-flow : row wrap;
  gap       : 10px;

  position  : relative;

  .select-holder {
    margin : 0;
  }
}

.select-holder {
  display   : flex;
  flex-flow : column nowrap;
  gap       : 3px;
  position  : relative;
  margin    : 15px 20px;
  //align-items : center;
  min-width : 120px;
  &.no-minwidth {
    min-width : auto;
  }
  label {
    //margin     : 0 25px;
    display   : block;
    //text-align : left;
    font-size : 12px;
    color     : #4F5A69;
  }
  select {
    position         : relative;
    display          : inline-flex;
    border-radius    : 8px;
    height           : 30px;
    justify-content  : center;
    align-items      : center;
    width            : 100%;
    padding-right    : 3px;
    background-color : #FFFFFF;
    color            : #4F5A69;
    //border           : 1px solid #4F5A69;
    cursor           : pointer;
    transition       : background-color 0.5s ease;
    font-size        : 14px;
    text-align       : center;
    &:focus-visible {
      outline : none;
    }
  }
  input {
    position         : relative;
    display          : inline-flex;
    border-radius    : 8px;
    height           : 30px;
    padding-right    : 3px;
    justify-content  : center;
    align-items      : center;
    width            : 100%;
    background-color : #FFFFFF;
    color            : #4F5A69;
    border           : 1px solid #4F5A69;
    cursor           : pointer;
    transition       : background-color 0.5s ease;
    font-size        : 14px;
    text-align       : center;
    &:focus-visible, &:focus {
      outline : none;
    }
  }
}
.items-list-holder .items-list-element .cell .select-holder{
  label {
    //margin     : 0 25px;
    display   : block;
    //text-align : left;
    font-size : 12px;
    color     : #4F5A69;
  }
  select {
    position         : relative;
    display          : inline-flex;
    border-radius    : 8px;
    height           : 30px;
    justify-content  : center;
    align-items      : center;
    width            : 100%;
    padding-right    : 3px;
    background-color : #FFFFFF;
    color            : #4F5A69;
    //border           : 1px solid #4F5A69;
    cursor           : pointer;
    transition       : background-color 0.5s ease;
    font-size        : 14px;
    text-align       : center;
    &:focus-visible {
      outline : none;
    }
  }
  input {
    position         : relative;
    display          : inline-flex;
    border-radius    : 8px;
    height           : 30px;
    padding-right    : 3px;
    justify-content  : center;
    align-items      : center;
    width            : 100%;
    background-color : #FFFFFF;
    color            : #4F5A69;
    border           : 1px solid #4F5A69;
    cursor           : pointer;
    transition       : background-color 0.5s ease;
    font-size        : 14px;
    text-align       : center;
    &:focus-visible, &:focus {
      outline : none;
    }
  }
}
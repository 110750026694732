@import '../common/variables';

.left-marker {
  position : absolute;
  left     : 0;
  top      : 3%;
  height   : 94%;
  &.px-8 {
    width         : 8px;
    border-radius : 4px;
  }
  &.px-6 {
    width         : 6px;
    border-radius : 3px;
  }
  &.px-4 {
    width         : 4px;
    border-radius : 2px;
  }
  &.color-green {
    background : @mate-green;
  }
  &.color-yellow {
    background : @mate-yellow;
  }
  &.color-red {
    background : @mate-red;
  }
  &.color-blue {
    background : @mate-blue;
  }
}
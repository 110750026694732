@import '../common/variables';

html, body {
  font-family : 'Open Sans', sans-serif;
  //font-family : 'Roboto', sans-serif;
  cursor      : default;
  //background  : linear-gradient(to right, #323D48, #92A9BC);
  //background  : url('../images/bg.png') no-repeat center / cover;
  background  : @main-bg;
  margin      : 0;
  padding     : 0;
  //height      : 100%;
  width: 100%;
}

* {
  box-sizing : border-box;
}
input {
  font-family : @font;
  color       : #4F5A69;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance : none;
  margin             : 0;
}
textarea {
  font-family : @font;
}

/* Firefox */
input[type=number] {
  -moz-appearance : textfield;
}
.clearfix::after {
  content : '';
  display : table;
  clear   : both;
}
.centerer-table {
  position : relative;
  display  : table;
  width    : 100%;
  height   : 100%;
}
.centerer-cell {
  position       : relative;
  display        : table-cell;
  vertical-align : middle;
  width          : auto;
  margin         : auto;
}
.noselect {
  -webkit-touch-callout : none; /* iOS Safari */
  -webkit-user-select   : none; /* Safari */
  -khtml-user-select    : none; /* Konqueror HTML */
  -moz-user-select      : none; /* Old versions of Firefox */
  -ms-user-select       : none; /* Internet Explorer/Edge */
  user-select           : none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.my-row {
  position        : relative;
  width           : 100%;
  margin-bottom   : 20px;

  display         : flex;
  flex-flow       : row nowrap;
  justify-content : flex-start;
  &.reverse {
    justify-content : flex-end;
  }
  &.max-width > div {
    flex-basis : 100%;
  }
  &.center {
    justify-content : center;
  }
  > div {
    margin-left : 20px;
    &:first-child {
      margin-left : 0;
    }
  }

  .grow {
    flex : 1 1 100%;
  }

  .no-shrink {
    flex-shrink : 0;
  }

  .no-grow {
    flex-grow : 0;
  }
}
.bottom_space_10px {
  margin-bottom : 10px;
}
.bottom_space_25px {
  margin-bottom : 25px;
}
.right_space_25px {
  margin-right : 25px;
}


.small-logo {
  position       : fixed;
  right          : 10px;
  bottom         : 10px;
  width          : 30px;
  height         : 20px;
  background     : url('@{path-img}/logo-07.png') no-repeat center/contain;
  pointer-events : none;
}

.max-width {
  max-width : 1920px;
  margin    : auto;
}
.left, .right {
  position    : relative;
  display     : flex;
  flex-flow   : row nowrap;
  align-items : end;
  //margin      : 0 0 15px;
  .button {
    margin-bottom : 0;
  }
}
.left {
  justify-content : start;
}
.right {
  justify-content : end;
}
.space-between {
  justify-content : space-between;
  margin          : 0 0 15px;
  align-items     : end;
}
.align-start {
  justify-content : start !important;
  margin          : 0 0 15px;
  align-items     : end;
}
.align-end {
  justify-content : end !important;
  margin          : 0 0 15px;
  align-items     : end;
}
.align-center {
  align-items : center;
}
.row {
  display   : flex;
  flex-flow : row wrap;
}
.app {
  position   : relative;
  width      : 100%;
  min-height : 100vh;
  //display  : flex;
}

.flex {
  display : flex;
}
.flex-column {
  display : flex;
  flex-flow : column;
}
.shrink {
  flex-shrink : 1;
}
.noshrink {
  flex-shrink : 0;
}
.justify-start {
  justify-content : flex-start !important;
}
.justify-end {
  justify-content : flex-end !important;
}
.justify-center {
  justify-content : center !important;
}
/*
.align-center {
  align-items : center;
}
.align-start {
  justify-content : start !important;
  //margin          : 0 0 15px;
  align-items     : end;
}
.align-end {
  justify-content : end !important;
  //margin          : 0 0 15px;
  align-items     : end;
}
*/
/*
.space-between {
  justify-content : space-between;
}
*/
.double-cloumn {
  display               : grid !important;
  grid-template-columns : repeat(2, 1fr) !important;
}
.wrap {
  flex-wrap : wrap !important;
}
.nowrap {
  flex-wrap : nowrap !important;
}
.grow {
  flex-grow : 1;
}

.scroll-thin-vertical{
  overflow        : auto;
  @-moz-document url-prefix(){
    scrollbar-width : none;
  }
  &::-webkit-scrollbar {
    //display          : block;
    width            : 6px;
    border-radius    : 3px;
    background-color : @white;
  }
  &::-webkit-scrollbar-thumb {
    width            : 6px;
    border-radius    : 3px;
    background-color : @dark-gray;
  }
  &::-webkit-scrollbar-button {
    display: none;
    opacity: .1;
  }
}
.scroll-thin-horizontal{
  overflow        : auto;
  @-moz-document url-prefix(){
    scrollbar-width : none;
  }
  &::-webkit-scrollbar {
    display          : block;
    height            : 6px;
    border-radius    : 3px;
    background-color : @white;
  }
  &::-webkit-scrollbar-thumb {
    height            : 6px;
    border-radius    : 3px;
    background-color : @dark-gray;
  }
  &::-webkit-scrollbar-button {
    //display: none;
  }
}

.search {
  position : relative;
  margin   : 0 25px 0 0;
  &.w-100p {
    margin : 0;
  }
  input {
    padding          : 0 15px;
    border-radius    : 8px;
    height           : 30px;
    width            : 200px;
    color            : #4F5A69;
    background-color : #FFFFFF;
    cursor           : text;
    //border           : 1px solid #94ACC2;
    border           : none;
    box-shadow       : @shadow;

    font-size        : 14px;
    &:focus {
      outline : none;
    }
  }
}

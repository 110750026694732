@import '../common/variables';
@import '../common/mixins';

.controls-holder {
  position        : relative;
  //padding         : 10px 25px;
  display         : flex;
  flex-flow       : row nowrap;
  justify-content : space-between;
  
  .resp(@tablet-big, {
    flex-flow       : column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  });
  
  
  .left,.right{
    .resp(@tablet-big, {
      flex-flow       : column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
    });
  }
  &.align-start {
    .filter-dropdown {
      margin : 0 25px;
    }
  }
  .button {
    display          : flex;

    margin           : 0 0 0 15px;
    border-radius    : 6px;
    padding          : 0 25px;
    height           : 30px;
    justify-content  : center;
    align-items      : center;
    width            : auto;
    min-width        : 100px;
    background-color : @base-blue;
    color            : #FFFFFF;
    cursor           : pointer;
    transition       : background-color .5s ease;

    font-size        : 14px;
    font-weight      : 400;
    
    .resp(@tablet-big, {
      margin           : 0;
    });

    &:hover {
      background-color : @base-blue-hover;
    }

    &.add-button {

    }
    &.back-button {
      //position : absolute;
      //left     : 0;
      margin : 0 25px 0 0;
    }
    &.back-button-arrow {
      //position : absolute;
      //left     : 0;
      margin          : 0 25px 0 0;
      min-width       : 0;
      padding         : 0;
      width           : 30px;
      height          : 30px;
      border          : 2px solid #222222;
      color           : #222222;
      background      : none;
      transition      : background-color .3s ease-in-out, border-color .3s ease-in-out;

      display         : flex;
      justify-content : center;
      align-items     : center;

      svg {
        position : relative;
        width    : 15px;
        height   : 15px;
        left     : -1px;
        path {
          fill       : #222222;
          transition : fill .3s ease-in-out;
        }
      }
      &:hover {
        background   : #222222;
        border-color : rgba(0, 0, 0, 0);
        color        : #FFFFFF;
        svg {
          path {
            fill : #FFFFFF;
          }
        }
      }
    }
  }
  .select {
    position : relative;
    width    : auto;
  }
}
.controls-back {
  position            : relative;
  width               : 48px;
  height              : 48px;
  border-radius       : 999px;
  border              : 1px solid #DBE9F5;
  background-image    : url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2225%22%20viewBox%3D%220%200%2024%2025%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20stroke%3D%22%2389A1B6%22%20d%3D%22M19%2012.5H5%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20stroke%3D%22%2389A1B6%22%20d%3D%22M12%2019.5L5%2012.5L12%205.5%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat   : no-repeat;
  background-color    : transparent;
  background-size     : 20px 20px;
  background-position : center;
  cursor              : pointer;
  transition          : 0.4s ease-out;
  &:hover {
    border-color        : #2981F2;
    background-image    : url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2225%22%20viewBox%3D%220%200%2024%2025%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20stroke%3D%22%232981F2%22%20d%3D%22M19%2012.5H5%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20stroke%3D%22%232981F2%22%20d%3D%22M12%2019.5L5%2012.5L12%205.5%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat   : no-repeat;
    background-color    : transparent;
    background-size     : 20px 20px;
    background-position : center;
  }
}

@import '../common/variables';

.tab-header {
  position : relative;

  display  : flex;
  z-index  : 1;
  .tab {
    position      : relative;
    padding       : 5px 10px 15px;
    margin        : 10px 4px -10px 0;
    border-radius : 6px;
    //box-shadow    : 10px 10px 5px -3px rgb(0 0 0 / 10%);
    box-shadow    : @shadow;
    //border-left   : 1px solid #7587A2;
    //border-top    : 1px solid #7587A2;
    //background    : #3B4452;
    background    : @base-blue;
    cursor        : pointer;
    top           : 0;

    font-size     : 14px;
    font-weight   : 300;
    color         : #E2E2E2;
    transition    : background-color .3s ease-in-out, color .3s ease-in-out, top .3s ease-in-out;
    &:before {
      content       : '';
      position      : absolute;
      top           : 0;
      right         : -6px;
      width         : 10px;
      height        : 100%;
      //background    : #3B4452;
      background    : @base-blue;
      border-radius : 6px;
      //border-right  : 1px solid #7587A2;

      transform     : skew(15deg, 0deg);
      transition    : background-color .3s ease-in-out;
    }
    &:after {
      content       : '';
      position      : absolute;
      bottom        : 0;
      right         : -6px;
      width         : 20px;
      height        : 10px;
      //background    : #3B4452;
      background    : @base-blue;
      border-radius : 6px;

      transform     : skew(15deg, 0deg);
      transition    : background-color .3s ease-in-out;
    }
    &:hover {
      z-index    : 1;
      background : @base-blue-hover;
      top        : -4px;
      &:before {
        background : @base-blue-hover;
      }
      &:after {
        background : @base-blue-hover;
      }
    }
    &.active {
      z-index    : 1;
      background : #FFFFFF;
      color      : #000000;
      top        : -4px;
      &:before {
        background : #FFFFFF;
      }
      &:after {
        background : #FFFFFF;
      }
    }
  }
  &.upside {
    overflow : hidden;
    .tab {
      margin     : -10px 0 15px;
      padding    : 15px 10px 5px;
      //border-left   : none;
      border-top : none;
      //border-left   : 1px solid #7587A2;
      //border-bottom : 1px solid #7587A2;
      &:before {
        transform : skew(-15deg, 0deg);
      }
      &:after {
        transform : skew(-15deg, 0deg);
        top       : 0;
        bottom    : auto;
      }
      &:hover {
        top : 4px;
      }
      &.active {
        top : 4px;
      }
    }
  }
  .inner-holder &.upside {
    top : -10px;
  }
  .page-content > & {
    .tab {
      padding             : 5px 10px 5px;
      margin              : 10px 4px 10px 0;
      box-shadow          : none;
      background          : none;
      cursor              : pointer;
      top                 : 0;

      border-bottom-color : rgba(255, 255, 255, 0);
      border-bottom-width : 1px;

      font-size           : 14px;
      font-weight         : 500;
      color               : @dark-blue;
      transition          : color .3s ease-in-out, border-bottom-color .3s ease-in-out, border-bottom-width .3s ease-in-out;

      &:before {
        display : none;
      }
      &:after {
        display : none;
      }
      &:hover {
        z-index       : 1;
        background    : none;
        top           : 0;

        border-radius : 0;
        border-bottom : 3px solid @mate-blue;

        font-weight   : 500;
        color         : @mate-blue;
        &:before {
          background : none;
        }
        &:after {
          background : none;
        }
      }
      &.active {
        z-index       : 1;
        background    : none;
        top           : 0;

        border-radius : 0;
        border-bottom : 3px solid @base-blue;

        font-weight   : 500;
        color         : @base-blue;
        &:before {
          background : none;
        }
        &:after {
          background : none;
        }
      }
    }
  }
}
.tab-header + .tab-holder {
  position : relative;
  z-index  : 2;
  .tab-blocker {
    position      : absolute;
    top           : 0;
    left          : 0;
    width         : 100%;
    height        : 100%;
    background: rgba(255, 255, 255, 0.4);
    border-radius : 8px;
    z-index       : 9999;
  }
}
.tab-name {
  position    : relative;
  padding     : 0 25px;
  font-size   : 28px;
  font-weight : 300;
  color       : #222222 !important;
  text-align  : left;
  margin      : 0 0 15px;
  //text-shadow : 1px 1px 3px rgb(56, 56, 56);
}

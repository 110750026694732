@import '../common/variables';
@import '../common/mixins';


.items-list-header {
  display  : block;
  position : relative;
  width    : fit-content;
  min-width: 100%;
  //padding : 0 25px;

  .cells {
    display       : flex;
    flex-flow     : row nowrap;
    width         : 100%;
    height        : 40px;
    //background    : #2B323C !important;
    background    : rgba(255, 255, 255, .5);
    border-radius : 6px;
    margin-bottom : 4px;
    //padding       : 5px 5px;
    //color         : #E2E2E2;
    color         : #2B323C;
    .resp(@tablet-big, {
      display: none;
    });

    > div:last-child {
      border-right : none !important;
    }
    .cell {
      position        : relative;
      padding         : 0 15px;
      height          : 100%;
      border-right    : 1px solid #FFFFFF !important;
      min-width       : 130px;

      flex            : 0 0 10%;

      display         : flex;
      justify-content : center;
      align-items     : center;

      font-size       : 14px;
      color           : #2B323C !important;
      text-align      : center;
      //font-weight     : bold;
      &.half {
        min-width : 80px;

        flex      : 0 0 5%;
      }
      &.left {
        text-align      : left;
        justify-content : flex-start;
      }
      &.center {
        text-align      : center;
        justify-content : center;
      }
      &:last-child {
        border-right : none;
      }
      &.grow {
        flex-grow       : 1;
        justify-content : flex-start;
        text-align      : left;
        &.center {
          text-align      : center;
          justify-content : center;
        }
      }
      &.shrink {
        flex-shrink : 1;
      }
      &.button {
        min-width : 120px;
      }

      &.w5percent{
        flex                      : 0 0 5%;
      }
      &.w10percent{
        flex                      : 0 0 10%;
      }
      &.w15percent{
        flex                      : 0 0 15%;
      }
      &.w20percent{
        flex                      : 0 0 20%;
      }

      .sort-button {
        display             : inline-block;
        position            : relative;
        width               : 25px;
        height              : 100%;
        right               : 0;
        top                 : 0;
        cursor              : pointer;
        .svg(@svg-down-arrow, @dark-blue);
        background-size     : 50%;
        background-position : center;
        background-repeat   : no-repeat;
        transition          : transform .3s ease-in-out;
        &.sort-up {
          transform        : rotate(180deg);
          transform-origin : center;
        }
      }
    }

    .dummy-dd-btn {
      position     : relative;
      //flex         : 0 0 5%;
      padding      : 10px 20px;
      border-right : 1px solid #FFFFFF;
      .add-button {
        position   : absolute;
        width      : 100%;
        height     : 100%;
        background : url("data:image/svg+xml,%3Csvg class='svg-icon' style='width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M544 464V160h-80v304H160v80h304v304h80V544h304v-80z' fill='%23DADBDC' /%3E%3C/svg%3E") no-repeat center/70%;
        top        : 0;
        left       : 0;
        cursor     : pointer;
        &:after {
          content    : '';
          position   : absolute;
          width      : 100%;
          height     : 100%;
          background : url("data:image/svg+xml,%3Csvg class='svg-icon' style='width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M544 464V160h-80v304H160v80h304v304h80V544h304v-80z' fill='%23ffffff' /%3E%3C/svg%3E") no-repeat center/70%;
          top        : 0;
          left       : 0;
          cursor     : pointer;
          opacity    : 0;
          transition : opacity .3s ease-in-out;
        }
        &:hover {
          &:after {
            opacity : 1;
          }
        }
      }
    }
  }
}

.page > .content-holder > .items-list-header > .cells {
  background : #E6E8EB;
}

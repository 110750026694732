@import '../common/variables';
@import '../common/mixins';

each(range(5, 100, 5), {
  //=============== margins ====================
  .mt-@{value} {
    margin-top: @value*1px !important;
  }
  .mb-@{value} {
    margin-bottom: @value*1px !important;
  }
  .ml-@{value} {
    margin-left: @value*1px !important;
  }
  .mr-@{value} {
    margin-right: @value*1px !important;
  }
  .mx-@{value} {
    margin-left: @value*1px !important;
    margin-right: @value*1px !important;
  }
  .my-@{value} {
    margin-top: @value*1px !important;
    margin-bottom: @value*1px !important;
  }
  //=============== paddings ====================
  .p-@{value} {
    padding: @value*1px !important;
  }
  .pt-@{value} {
    padding-top: @value*1px !important;
  }
  .pb-@{value} {
    padding-bottom: @value*1px !important;
  }
  .pl-@{value} {
    padding-left: @value*1px !important;
  }
  .pr-@{value} {
    padding-right: @value*1px !important;
  }
  .px-@{value} {
    padding-left: @value*1px !important;
    padding-right: @value*1px !important;
  }
  .py-@{value} {
    padding-top: @value*1px !important;
    padding-bottom: @value*1px !important;
  }
  //=============== positions ====================
  .t-@{value} {
    top: @value*1px !important;
  }
  .l-@{value} {
    left: @value*1px !important;
  }
  .b-@{value} {
    bottom: @value*1px !important;
  }
  .r-@{value} {
    right: @value*1px !important;
  }
});
.pr-200{
  padding-right: 200px !important;
}
.pr-300{
  padding-right: 300px !important;
}
//=============== flex ====================
each(range(5, 30, 5), {
  .g-@{value} {
    gap: @value*1px !important;
  }
});


//=============== flex width pixels ====================
each(range(50, 500, 25), {
  .fwpx-@{value} {
    flex-basis: @value*1px !important;
  }
});
//=============== flex width percents ====================
each(range(10, 100, 5), {
  .fwp-@{value} {
    flex-basis: @value*1% !important;
  }
});
.fwp-33{
  flex-basis: 1/3% !important;
}
//=============== width pixels ====================
each(range(50, 500, 25), {
  .wpx-@{value} {
    width: @value*1px !important;
  }
});
//=============== width percents ====================
each(range(10, 100, 5), {
  .wp-@{value} {
    width: @value*1% !important;
  }
});
.wp-33{
  width: 1/3% !important;
}
//=============== height pixels ====================
each(range(50, 500, 25), {
  .hpx-@{value} {
    height: @value*1px !important;
  }
});

//=============== height ====================
.h-30 {
  height : 30px !important;
}
.h-60 {
  height : 60px !important;
}
.h-90 {
  height : 90px !important;
}
.h-120 {
  height : 120px !important;
}
.h-150 {
  height : 150px !important;
}
.h-180 {
  height : 180px !important;
}
.h-210 {
  height : 210px !important;
}
.h-240 {
  height : 240px !important;
}
.h-270 {
  height : 270px !important;
}

//=============== width ====================
.w-100p {
  width : 100% !important;
}

.w-30px {
  width : 30px !important;
}
.w-60px {
  width : 60px !important;
}
.w-90px {
  width : 90px !important;
}
.w-120px {
  width : 120px !important;
}
.w-150px {
  width : 150px !important;
}
.w-180px {
  width : 180px !important;
}
.w-210px {
  width : 210px !important;
}
.w-240px {
  width : 240px !important;
}
.w-270px {
  width : 270px !important;
}
.w-300px {
  width : 300px !important;
}
.w-330px {
  width : 330px !important;
}
.w-360px {
  width : 360px !important;
}
.w-390px {
  width : 390px !important;
}
.w-420px {
  width : 420px !important;
}
.w-450px {
  width : 450px !important;
}
.w-480px {
  width : 480px !important;
}
.w-510px {
  width : 510px !important;
}
.w-540px {
  width : 540px !important;
}
.w-570px {
  width : 570px !important;
}
.w-600px {
  width : 600px !important;
}

.wf-90px {
  flex : 0 0 90px !important;
}
.wf-120px {
  flex : 0 0 120px !important;
}
.wf-150px {
  flex : 0 0 150px !important;
}
.wf-180px {
  flex : 0 0 180px !important;
}


.aqua {
  background-color : @mate-aqua !important;
}
.green {
  background-color : @mate-green !important;
}
.green-1 {
  background-color : #D2FFD6 !important;
}
.yellow {
  background-color : @mate-yellow !important;
}
.blue {
  background-color : @mate-blue !important;
}
.red {
  background-color : @mate-red !important;
}
.red-1 {
  background-color : #FFAAAA !important;
}
.red-2 {
  background-color : #F8CFCF !important;
}
.gray-1 {
  background-color : #EEEEEE !important;
}
.gray-2 {
  background-color : #AAAAAA !important;
}

.bb-aqua {
  border-bottom : 2px solid @mate-aqua !important;
}
.bb-green {
  border-bottom : 2px solid @mate-green !important;
}
.bb-green-1 {
  border-bottom : 2px solid #D2FFD6 !important;
}
.bb-yellow {
  border-bottom : 2px solid @mate-yellow !important;
}
.bb-blue {
  border-bottom : 2px solid @mate-blue !important;
}
.bb-dark-blue {
  border-bottom : 2px solid @base-blue !important;
}
.bb-red {
  border-bottom : 2px solid @mate-red !important;
}
.bb-red-1 {
  border-bottom : 2px solid #FFAAAA !important;
}
.bb-red-2 {
  border-bottom : 2px solid #F8CFCF !important;
}
.bb-gray-1 {
  border-bottom : 2px solid #EEEEEE !important;
}
.bb-gray-2 {
  border-bottom : 2px solid #AAAAAA !important;
}

@import '../common/variables';
@import '../common/mixins';

.input-wd {
  display   : flex;
  flex-flow : row nowrap;

  position  : relative;
  width     : 100%;

  input {
    padding          : 0 15px;
    border-radius    : 8px;
    height           : 30px;
    width            : 100%;
    color            : #4F5A69;
    background-color : #FFFFFF;
    cursor           : text;
    border           : none;
    box-shadow       : 10px 10px 5px -3px rgba(0, 0, 0, 0.03);
    font-size        : 14px;
    &:focus {
      outline : none;
    }
  }
  .input-wd-title {
    padding-left : 15px;
  }
  .input-wd-description {
    display     : flex;
    align-items : center;

    height      : 30px;

    font-size   : 14px;
  }
}
@import '../common/variables';
@import '../common/mixins';

.page {
  & > .buttons-holder {
    justify-content : left;
    padding         : 0 25px;
    margin-bottom   : 20px;
    .button {
      &.active {
        background-color : #1E559F;
        cursor           : default;
      }
    }
  }
  .page-right-column {
    position                  : fixed;
    top                       : 0;
    right                     : 0;
    width                     : 300px;
    height                    : 100%;
    background                : #dadfe8;
    border-top-left-radius    : 8px;
    border-bottom-left-radius : 8px;
    padding                   : 10px;
    z-index                   : 100;

    overflow                  : auto;

    .page-right-column-header {
      position   : relative;

      font-size  : 16px;
      //font-weight : 300;
      color      : #222222 !important;
      text-align : left;
    }
    .date-input {

    }
    .page-right-column-empty {
      display            : flex;
      justify-content    : center;
      align-items        : center;
      flex-flow          : column nowrap;

      position           : absolute;
      top                : 0;
      left               : 0;
      width              : 100%;
      height             : 100%;
      -webkit-box-shadow : inset 0px 0px 163px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow    : inset 0px 0px 163px 0px rgba(0, 0, 0, 0.2);
      box-shadow         : inset 0px 0px 163px 0px rgba(0, 0, 0, 0.2);

      .prc-empty-icon {
        position : relative;
        .svg(@svg-empty-window, @dark-blue);
        width    : 45px;
        height   : 45px;
        margin   : 0 auto;
      }
      .prc-empty-text {
        position   : relative;
        font-size  : 20px;
        text-align : center;
        width      : 75%;
        margin     : 10px auto 0;
      }
    }
  }
}
.page-content {
  position      : relative;
  padding       : 0 25px;
  margin-bottom : 15px;
  
  .resp(@tablet-big, {
    padding       : 0 10px;
  });
  
  .page-content-text {
    font-size     : 16px;
    font-weight   : 300;
    margin-bottom : 10px;
    margin-right  : 15px;
    &.icon-upload {
      background : url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Crect x='111.296' y='262.421' width='137.918' height='31.345'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Crect x='110.314' y='135.578' width='137.918' height='31.345'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Crect x='110.314' y='199.313' width='250.761' height='31.345'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpolygon points='374.189,377.268 374.189,337.125 342.844,337.125 342.844,377.268 304.703,377.268 304.703,408.613 342.844,408.613 342.844,448.757 374.189,448.757 374.189,408.613 412.328,408.613 412.328,377.268 '/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath d='M434.385,304.812V106.726L328.227,0H36.68v511.97h318.296c0.802,0.016,1.605,0.03,2.411,0.03 c65.027,0,117.932-52.904,117.932-117.932C475.32,358.44,459.439,326.454,434.385,304.812z M329.206,45.431l51.812,52.09h-51.812 V45.431z M68.026,480.625V31.345h229.836v97.521H403.04v156.467c-14.049-5.92-29.474-9.197-45.651-9.197 c-65.027,0-117.932,52.904-117.932,117.932c0,34.177,14.618,64.998,37.923,86.556H68.026z M358.595,480.625h-2.414 c-47.188-0.651-85.38-39.217-85.38-86.556c0-47.744,38.843-86.587,86.587-86.587c47.744,0,86.587,38.843,86.587,86.587 C443.975,441.408,405.782,479.974,358.595,480.625z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center / contain;
    }
    label {
      display       : block;
      margin-right  : 15px;
      margin-bottom : 5px;
      font-size     : 14px;
    }
    input {
      padding          : 0 15px;
      border-radius    : 8px;
      height           : 30px;
      width            : 250px;
      color            : #4F5A69;
      background-color : #FFFFFF;
      cursor           : text;
      border           : 1px solid #94ACC2;

      font-size        : 14px;
      &:focus {
        outline : none;
      }

      &[type='file'] {
        &::-webkit-file-upload-button { /* chromes and blink button */
          cursor : pointer;
        }

        opacity : 0;
        cursor  : pointer !important;
      }


    }
  }
  .button {
    display          : flex;

    margin           : 10px 0;
    border-radius    : 8px;
    height           : 30px;
    justify-content  : center;
    align-items      : center;
    //width            : 100px;
    background-color : #4F5A69;
    color            : #FFFFFF;
    cursor           : pointer;
    transition       : background-color .5s ease;

    font-size        : 14px;

    &:hover {
      background-color : #1E559F;
    }
    &.color-red {
      background-color : @mate-red;
      &:hover {
        background-color : @mate-red-dark;
      }
    }
  }
  .info-element {
    .button {
      margin    : 15px;
      display   : inline-flex;
      min-width : 150px;
    }
  }
}
.page-header {
  position      : relative;
  height        : 50px;
  padding       : 0 25px;

  font-size     : 16px;
  font-weight   : 300;
  color         : #222222 !important;
  text-align    : left;
  margin        : 0;
  //text-shadow : 1px 1px 3px rgb(197, 197, 197);

  display       : flex;
  align-items   : center;
  //background  : #E2E5E9;
  //background  : linear-gradient(30deg, #E2E5E9 0%, #FFFFFF 100%);
  background    : none;
  border-bottom : #FFFFFF;
}

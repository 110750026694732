@import '../common/variables';
@import '../common/mixins';

.offers-grid {
  display         : flex;
  flex-flow       : row wrap;
  justify-content : flex-end;
  align-items     : flex-start;
  gap             : 5px;

  position        : relative;
  width           : 100%;

  &.offer-vertical {
    .offer_day_title {
      align-self      : stretch;

      display         : flex;
      flex-flow       : row;
      justify-content : flex-start;
      align-items     : center;
      gap             : 5px;

      width           : 100%;

      text-align      : left;
      font-weight     : 400;
      font-size       : 14px;

      div {
        &:first-child {
          font-size : 10px;
          //writing-mode     : vertical-lr;
          //text-orientation : upright;
        }
        &:last-child {
          font-size   : 14px;
          font-weight : 700;
        }
      }
    }
    .offer {
      display         : flex;
      justify-content : flex-start;
      align-items     : stretch;
      flex-flow       : row nowrap;

      position        : relative;
      width           : 100%;
      height          : 55px;
      border-radius   : 8px;
      background      : @white;
      overflow        : hidden;
      box-shadow      : @shadow;
      transition      : transform .2s ease-out, box-shadow .2s ease-out;
      &.hand {
        cursor : grab;
        &:active {
          cursor : grabbing;
        }
        &:hover {
          transform  : translateY(-3px);
          box-shadow : @shadow-hover;
        }
      }
      &.double {
        height : 80px;
        .offer-title {
          -webkit-line-clamp : 4;
          div {
            -webkit-line-clamp : 3;
          }
        }
      }
      &.double-ext {
        height : 90px;
        .offer-title {
          -webkit-line-clamp : 4;
          div {
            -webkit-line-clamp : 3;
          }
        }
      }
      &.triple {
        height : 120px;
        .offer-title {
          -webkit-line-clamp : 6;
          div {
            -webkit-line-clamp : 5;
          }
        }
      }
    }
    .offer-tslot {
      display         : flex;
      justify-content : flex-start;
      align-items     : stretch;
      flex-flow       : row nowrap;

      position        : relative;
      width           : 100%;
      height          : 65px;
      border-radius   : 8px;
      background      : @white;
      border          : 2px solid @white;
      overflow        : hidden;
      box-shadow      : @shadow;
      cursor          : pointer;
      transition      : border .4s ease-out;

      &:hover {
        border : 2px solid @mate-blue;
      }
      &.selected {
        border : 2px solid @base-blue-hover;
        &:hover {
          border : 2px solid @mate-blue;
        }
      }
      &.hand {
        cursor : grab;
        &:active {
          cursor : grabbing;
        }
      }
      &.double {
        height : 80px;
        .offer-title {
          -webkit-line-clamp : 4;
          div {
            -webkit-line-clamp : 3;
          }
        }
      }
      &.triple {
        height : 120px;
        .offer-title {
          -webkit-line-clamp : 6;
          div {
            -webkit-line-clamp : 5;
          }
        }
      }
    }
    .offer_add {
      align-self      : stretch;

      display         : flex;
      flex-flow       : column;
      justify-content : center;
      align-items     : center;

      cursor          : pointer;
      padding         : 0 8px;
      background      : @white;
      border-radius   : 3px;
      box-shadow      : @shadow;
      transition      : box-shadow .4s ease-out;

      font-weight     : 700;
      &:hover {
        box-shadow : @shadow2;
      }

    }
    .offer-img {
      flex-shrink : 0;
      flex-grow   : 0;

      position    : relative;
      height      : 100%;
      width       : 40px;
    }
    .offer-img-timeslot {
      flex-shrink         : 0;
      flex-grow           : 0;

      position            : relative;
      height              : 100%;
      width               : 40px;

      .svg(@svg-clock, @dark-blue);
      background-position : center 5px;
      background-size     : 60%;
    }
    .close-btn {
      position      : absolute;
      width         : 20px;
      height        : 20px;
      top           : 5px;
      right         : 5px;
      border-radius : 50%;
      background    : #898989 url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.6195 0.258548C15.3684 0.0891683 15.0349 0.056065 14.7577 0.178548C14.55 0.266272 14.3976 0.442272 14.2408 0.600616C12.2178 2.658 10.1953 4.71648 8.17167 6.77386C6.14699 4.71648 4.12448 2.65689 2.10035 0.598961C1.93655 0.431789 1.77709 0.2431 1.55038 0.164755C1.19784 0.0224099 0.767735 0.139927 0.526379 0.436203C0.293159 0.708203 0.240008 1.12144 0.396212 1.44586C0.482991 1.64172 0.648957 1.78075 0.793229 1.93137C2.81628 3.98931 4.83987 6.04669 6.86238 8.10572C4.82143 10.1835 2.77885 12.2597 0.737363 14.3369C0.447193 14.5747 0.239464 14.957 0.323532 15.3438C0.403261 15.8376 0.919601 16.1973 1.40123 16.0897C1.67947 16.045 1.88828 15.837 2.07485 15.639C4.10713 13.5716 6.13885 11.5043 8.17167 9.43758C10.269 11.5683 12.3626 13.7024 14.4605 15.8326C14.7951 16.183 15.3966 16.1979 15.7416 15.8552C16.0247 15.5959 16.1174 15.1496 15.9607 14.7959C15.8777 14.5874 15.7047 14.4395 15.5534 14.2834C13.5292 12.2243 11.5046 10.1659 9.48096 8.10572C11.5219 6.02737 13.565 3.95124 15.6065 1.874C15.8175 1.69248 15.9981 1.44751 16.029 1.16062C16.0768 0.812478 15.9097 0.448341 15.6195 0.258548Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") no-repeat center/50%;
      cursor        : pointer;
      transition    : background-color .4s ease-out;
      &:hover {
        background : @left-menu-color url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.6195 0.258548C15.3684 0.0891683 15.0349 0.056065 14.7577 0.178548C14.55 0.266272 14.3976 0.442272 14.2408 0.600616C12.2178 2.658 10.1953 4.71648 8.17167 6.77386C6.14699 4.71648 4.12448 2.65689 2.10035 0.598961C1.93655 0.431789 1.77709 0.2431 1.55038 0.164755C1.19784 0.0224099 0.767735 0.139927 0.526379 0.436203C0.293159 0.708203 0.240008 1.12144 0.396212 1.44586C0.482991 1.64172 0.648957 1.78075 0.793229 1.93137C2.81628 3.98931 4.83987 6.04669 6.86238 8.10572C4.82143 10.1835 2.77885 12.2597 0.737363 14.3369C0.447193 14.5747 0.239464 14.957 0.323532 15.3438C0.403261 15.8376 0.919601 16.1973 1.40123 16.0897C1.67947 16.045 1.88828 15.837 2.07485 15.639C4.10713 13.5716 6.13885 11.5043 8.17167 9.43758C10.269 11.5683 12.3626 13.7024 14.4605 15.8326C14.7951 16.183 15.3966 16.1979 15.7416 15.8552C16.0247 15.5959 16.1174 15.1496 15.9607 14.7959C15.8777 14.5874 15.7047 14.4395 15.5534 14.2834C13.5292 12.2243 11.5046 10.1659 9.48096 8.10572C11.5219 6.02737 13.565 3.95124 15.6065 1.874C15.8175 1.69248 15.9981 1.44751 16.029 1.16062C16.0768 0.812478 15.9097 0.448341 15.6195 0.258548Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") no-repeat center/50%;
      }
    }
    .offer-title {
      flex-grow          : 1;

      position           : relative;
      margin             : 5px 40px 5px 10px;

      font-size          : 12px;
      line-height        : 1.25;
      overflow           : hidden;
      text-overflow      : ellipsis;
      white-space        : initial;
      display            : -webkit-box;
      -webkit-line-clamp : 3;
      -webkit-box-orient : vertical;
      div:not(.button) {
        //position           : relative;
        overflow           : hidden;
        text-overflow      : ellipsis;
        display            : -webkit-box;
        -webkit-line-clamp : 2;
        -webkit-box-orient : vertical;
      }
    }
    .offer_time_ico {
      //display         : flex;
      //justify-content : flex-start;
      &:before {
        content             : '';
        position            : relative;
        display             : inline-block;
        vertical-align      : bottom;
        width               : 14px;
        height              : 14px;
        margin-right        : 3px;
        .svg(@svg-calendar, @dark-blue);
        background-position : center;
        background-size     : 80%;
      }
    }
    .offer_click_ico {
      //position : absolute;
      //top      : 0;
      //bottom   : 0;
      //margin   : auto 0;
      //display         : flex;
      //justify-content : flex-start;

      &:before {
        content             : '';
        position            : relative;
        display             : inline-block;
        vertical-align      : bottom;
        width               : 14px;
        height              : 14px;
        margin-right        : 3px;
        .svg(@svg-pointer-click, @dark-blue);
        background-position : center;
        background-size     : 80%;
      }
    }
  }
}

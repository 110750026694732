@import '../common/variables';
@import '../common/mixins';

.pagination {
  position        : relative;
  display         : flex;
  //padding         : 0 25px;
  justify-content : space-between;
  align-items     : baseline;

  .prev-button, .next-button, .cells .pagination-item {
    position         : relative;
    display          : flex;
    margin-left      : 15px;
    border-radius    : 8px;
    padding          : 0 12px;
    height           : 30px;
    min-width        : 35px;
    justify-content  : center;
    align-items      : center;
    //width            : 100px;
    background-color : @white;
    border           : 1px solid @lite-lite-gray;
    color            : #252631;
    cursor           : pointer;
    transition       : background-color .5s ease, color .5s ease-in-out;
    box-shadow       : @shadow;

    font-size        : 14px;
    &:hover {
      color            : @white;
      border           : 1px solid @base-blue;
      background-color : @base-blue-hover;
    }
    &.disabled {
      cursor  : default;
      opacity : 0;
      //border  : 1px solid #898989;
      &:hover {
        background-color : @white;
      }
    }
    &.selected {
      font-weight      : bold;
      box-sizing       : border-box;
      cursor           : default;
      //border           : 1px solid #FFFFFF;
      color            : @white;
      border           : 1px solid @base-blue;
      background-color : @base-blue;
      &:hover {
        color            : @white;
        border           : 1px solid @base-blue;
        background-color : @base-blue;
      }
    }
  }
  .prev-button {
    .svg(@svg-left-arrow2, @dark-blue);
    background-color    : @white;
    background-size     : 65%;
    background-position : center;
    margin              : 0;
    &:hover {
      .svg(@svg-left-arrow2, @white);
      background-color    : @base-blue;
      background-size     : 65%;
      background-position : center;
    }
  }
  .next-button {
    .svg(@svg-right-arrow2, @dark-blue);
    background-color    : @white;
    background-size     : 65%;
    background-position : center;
    margin              : 0;
    &:hover {
      .svg(@svg-right-arrow2, @white);
      background-color    : @base-blue;
      background-size     : 65%;
      background-position : center;
    }
  }
  .cells {
    position        : relative;
    display         : flex;
    justify-content : center;
    margin-top      : 25px;
    .pagination-space {
      position        : relative;
      display         : flex;
      margin-left     : 15px;
      padding         : 0 12px;
      height          : 30px;
      justify-content : center;
      align-items     : center;

      font-size       : 14px;
      font-weight     : bold;
      color           : #252631 !important;
      text-align      : left;
      //text-shadow     : 1px 1px 3px rgb(56, 56, 56);
    }
  }
}
@import '../common/variables';
@import '../common/mixins';

.button {
  display          : flex;
  justify-content  : center;
  align-items      : center;

  border-radius    : 8px;
  height           : calc(100% - 10px);
  max-height       : 30px;
  width            : 100%;
  background-color : @white;
  color            : @dark-blue;
  border           : 1px solid @lite-gray;
  cursor           : pointer;
  transition       : background-color .5s ease-in-out, color .5s ease-in-out;

  font-size        : 14px;

  &:hover {
    background-color : @base-blue-hover;
    color            : @white;
  }

  &.red {
    color : @white;
    &:hover {
      color      : @mate-red;
      background : @white !important;
      border     : 1px solid @mate-red;
    }
  }
  &.green {
    //color            : #575974;
    color : @white;
    &:hover {
      color      : @mate-green;
      background : @white !important;
      border     : 1px solid @mate-green;
    }
  }
  &.blue{
    color : @white;
    &:hover {
      color      : @mate-blue;
      background : @white !important;
      border     : 1px solid @mate-blue;
    }
  }

}

@import '../common/variables';

.popups {
  position        : fixed;
  top             : 0;
  left            : 0;
  width           : 100vw;
  height          : 100vh;
  z-index         : 100;
  display         : flex;
  justify-content : center;
  align-items     : center;

  .bg {
    position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    background : rgba(0, 0, 0, .4);
  }
  .bg-login {
    position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    background : url("@{path-img}/LOGIN_bg.png") no-repeat center/cover;
  }
  .popup {
    position      : relative;
    //width      : 100%;
    background    : #FFFFFF;
    max-width     : 1140px;
    max-height    : 96vh;
    padding       : 25px 50px;
    border-radius : 8px;
    //box-shadow    : 0px 10px 22px -3px rgba(0, 0, 0, .42);
    box-shadow    : @shadow2;
    margin        : auto;
    box-sizing    : border-box;
    overflow      : auto;
    text-align    : center;

    &.popup-login {
      &.no-bg {
        background : no-repeat;
        box-shadow : none;
      }
    }
    &.popup-confirm {
      .controls-holder {
        .button {
          margin : 0 25px !important;
          &:last-child {
            margin : 0 25px;
          }
        }
      }
    }
    &.popup-add-xls {
      .buttons-holder {
        margin          : 20px 0;
        justify-content : center;
        .button {
          margin : 0 25px;
        }
      }
    }
    &.popup-create {
      min-width : 620px;
      max-width : 640px;
      &.no-minwidth {
        min-width : auto;
      }
      .info-content {
        flex-flow       : column nowrap;
        justify-content : center;
        align-items     : center;
        .title {
          margin : 0;
        }
      }
      .controls-holder {
        justify-content : center;
        gap             : 15px;
        .button {
          margin : 0;
        }
      }
      @media all and(max-width : 640px) {
        min-width : auto;
        width     : 100%;
      }
    }
    &.popup-create-customer {
      padding   : 25px;
      min-width : 570px;
      max-width : 570px;
      .info-content {
        flex-flow       : row wrap;
        justify-content : center;
        align-items     : flex-start;
        .title {
          position    : relative;
          padding     : 10px;
          margin      : 0;

          font-size   : 13px;
          font-weight : 500;
          text-align  : left;
          &.hint-icon {
            padding-right : 32px;
            .icon {
              content         : '?';
              position        : absolute;
              display         : inline-flex;
              right           : 10px;
              top             : 11px;
              width           : 12px;
              height          : 12px;
              border-radius   : 50%;
              border          : 1px solid @dark-gray;
              justify-content : center;
              align-items     : center;
              font-size       : 8px;
            }
          }
          .cross {
            position : absolute;
            right    : 10px;
            margin   : auto 0;
            top      : 0;
            bottom   : 0;
          }
        }
      }
      .controls-holder {
        justify-content : center;
        gap             : 15px;
        .button {
          margin : 0;
        }
      }
      @media all and(max-width : 640px) {
        min-width : auto;
        width     : 100%;
      }
    }

    .title {
      position      : relative;
      margin-bottom : 25px;

      font-size     : 16px;
      text-align    : center;
      font-weight   : 300;
    }
    label {
      position      : relative;
      display       : block;
      margin-bottom : 15px;
      padding       : 10px 15px;
    }
    input {
      position         : relative;
      display          : block;
      margin-bottom    : 15px;
      border           : none;
      padding          : 10px 15px;
      background-color : #F1F1F1;
      border-radius    : 8px;
      transition       : outline-color .5s ease-in-out;
      outline-color    : rgba(211, 211, 211, 0);
      &:focus {
        outline-color : rgba(211, 211, 211, 100);
        outline       : solid 2px #D3D3D3;
      }
      &.error {
        outline-color : rgb(255, 51, 51, 100);
        outline       : solid 2px #FF3333;
      }
    }
    .controls-holder {
      position : relative;
      display  : flex;
      .button {
        margin-top   : 0;
        margin-right : 20px;
        &:last-child {
          margin-right : 0;
        }
      }
    }
    .button {
      position         : relative;
      margin           : 25px auto 0;
      padding          : 0 25px;

      display          : inline-flex;
      border-radius    : 8px;
      height           : 30px;
      justify-content  : center;
      align-items      : center;
      min-width        : 100px;
      background-color : #4F5A69;
      color            : #FFFFFF;
      cursor           : pointer;
      transition       : background-color .5s ease;

      font-size        : 13px;
      font-weight      : 300;

      &:hover {
        background-color : #1E559F;
      }
      &.red {
        background-color : #E84000;
        &:hover {
          background-color : #FF4E4E;
        }
      }
      &.green {
        //color            : #575974;
        background-color : #0DA200;
        &:hover {
          background-color : #00BF00;
        }
      }
      &.shadow {
        box-shadow : @shadow4;
      }
    }
    .selects {
      position        : relative;
      display         : flex;
      justify-content : center;
    }
    .input-holder {
      position        : relative;
      display         : flex;
      justify-content : center;
      margin          : 15px 0;
    }
    .cross {
      position        : absolute;
      right           : 15px;
      top             : 15px;

      padding         : 20px 20px;
      background      : url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.6195 0.258548C15.3684 0.0891683 15.0349 0.056065 14.7577 0.178548C14.55 0.266272 14.3976 0.442272 14.2408 0.600616C12.2178 2.658 10.1953 4.71648 8.17167 6.77386C6.14699 4.71648 4.12448 2.65689 2.10035 0.598961C1.93655 0.431789 1.77709 0.2431 1.55038 0.164755C1.19784 0.0224099 0.767735 0.139927 0.526379 0.436203C0.293159 0.708203 0.240008 1.12144 0.396212 1.44586C0.482991 1.64172 0.648957 1.78075 0.793229 1.93137C2.81628 3.98931 4.83987 6.04669 6.86238 8.10572C4.82143 10.1835 2.77885 12.2597 0.737363 14.3369C0.447193 14.5747 0.239464 14.957 0.323532 15.3438C0.403261 15.8376 0.919601 16.1973 1.40123 16.0897C1.67947 16.045 1.88828 15.837 2.07485 15.639C4.10713 13.5716 6.13885 11.5043 8.17167 9.43758C10.269 11.5683 12.3626 13.7024 14.4605 15.8326C14.7951 16.183 15.3966 16.1979 15.7416 15.8552C16.0247 15.5959 16.1174 15.1496 15.9607 14.7959C15.8777 14.5874 15.7047 14.4395 15.5534 14.2834C13.5292 12.2243 11.5046 10.1659 9.48096 8.10572C11.5219 6.02737 13.565 3.95124 15.6065 1.874C15.8175 1.69248 15.9981 1.44751 16.029 1.16062C16.0768 0.812478 15.9097 0.448341 15.6195 0.258548Z' fill='%23929292'/%3E%3C/svg%3E%0A") no-repeat center;
      cursor          : pointer;

      display         : flex;
      //flex            : 0 0 5%;
      justify-content : center;
      align-items     : center;

      font-size       : 16px;
      font-weight     : bold;
    }
    .info-content {
      .info-element {
        input {
          margin : 0;
        }
      }
    }
  }
}

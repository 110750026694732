@import '../common/variables';
@import '../common/mixins';

.round-bar {
  position        : relative;
  width           : 84px;
  height          : 84px;
  overflow        : hidden;
  border-radius   : 50%;
  display         : flex;
  justify-content : center;
  align-items     : center;
  .ico {
    position   : relative;
    width      : 36px;
    height     : 36px;
    background : url('@{path-img}/square-giftbox-with-big-ribbon-svgrepo-com4.svg') no-repeat center/contain;
    &.gray {
      background : url('@{path-img}/square-giftbox-with-big-ribbon-svgrepo-com5.svg') no-repeat center/contain;
    }
  }
  &.picture {
    @circle_size : 84px;
    .circle-wrap {
      margin        : @circle_size auto;
      width         : @circle_size;
      height        : @circle_size;
      background    : #00E117;
      border-radius : 50%;
      //border: 1px solid #cdcbd0;
    }

    .circle-wrap .circle .mask,
    .circle-wrap .circle .fill {
      width         : @circle_size;
      height        : @circle_size;
      position      : absolute;
      border-radius : 50%;
    }

    .circle-wrap .circle .mask {
      clip : rect(0px, @circle_size, @circle_size, calc(@circle_size / 2));
    }

    .circle-wrap .inside-circle {
      width         : calc(@circle_size - 8px);
      height        : calc(@circle_size - 8px);
      border-radius : 50%;
      background    : #D2EAF1;
      line-height   : calc(@circle_size - 8px);
      text-align    : center;
      margin-top    : 4px;
      margin-left   : 4px;
      color         : #00860D;
      position      : absolute;
      //z-index       : 100;
      font-weight   : 700;
      font-size     : 2em;
      //background    : url('@{path-img}/Rectangle.png') no-repeat center/cover;
      border        : 1px solid rgba(0, 0, 0, 0.28);
    }

    /* 3rd progress bar */
    .mask .fill {
      clip             : rect(0px, calc(@circle_size / 2), @circle_size, 0px);
      background-color : #00860D;
    }

    .mask.full,
    .circle .fill {
      transform : rotate(0deg); // крутить сюда
    }

    /*@keyframes fill {
      0% {
        transform : rotate(0deg);
      }
      100% {
        transform : rotate(135deg);
      }
    }*/
  }
}

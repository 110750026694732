@import '../common/variables';
@import '../common/mixins';

.range {
  position : relative;
  width    : 100%;
  .range-inputs {
    display         : flex;
    flex-flow       : row nowrap;
    align-items     : center;
    justify-content : space-between;

    position        : relative;
    width           : 100%;
    .range-input {
      flex-shrink     : 0;

      display         : flex;
      justify-content : center;
      align-items     : center;
      gap             : 10px;

      position        : relative;
      width           : 90px;
      height          : 40px;
      padding         : 5px 12px;
      border-radius   : 8px;
      background      : var(--input-input, #ECF4FC);
      input {
        position       : relative;
        width          : 100%;
        background     : none;
        border         : none;
        outline        : none;

        color          : @dark-blue;
        font-size      : 14px;
        line-height    : 1;
        text-transform : capitalize;
        text-align     : center;
      }
    }
    .range-dash {
      position   : relative;
      width      : 8px;
      height     : 1px;
      background : @dark-gray;
    }
  }
  .range-slider {
    position : relative;
    width    : 100%;
    height   : 16px;
    .range-rail {
      position   : absolute;
      left       : 0;
      top        : 0;
      bottom     : 0;
      margin     : auto;
      width      : 100%;
      height     : 2px;
      background : @lite-gray;
    }
    .range-selected {
      position : absolute;
      height   : 100%;
      &:before {
        content    : '';
        position   : absolute;
        left       : 0;
        top        : 0;
        bottom     : 0;
        margin     : auto;
        width      : 100%;
        height     : 2px;
        background : @mate-blue;
      }
    }
    .range-points {
      position : absolute;
      left     : 0;
      width    : 100%;
      .range-point {
        position      : absolute;
        top           : 0;
        width         : 16px;
        height        : 16px;
        border-radius : 99px;
        background    : @left-menu-color;
        cursor        : pointer;
        &.range-point-0 {
          left      : 0;
          transform : translateX(-50%);
        }
        &.range-point-1 {
          left      : 0;
          transform : translateX(-50%);
        }
      }
    }
  }
}
@import '../common/variables';
@import '../common/mixins';

.datepicker {
  position : relative;
  
  display  : flex;
  gap      : 25px;
  .date-input {
    //margin : 0 12.5px;
    input[type="date"] {
      width : 200px;
    }
  }
  
  .resp(@tablet-big, {
    flex-flow       : column nowrap;
    justify-content : flex-start;
    align-items     : flex-start;
    gap             : 8px;
  });
}
.date-input {
  position : relative;
  
  //&:first-child {
  //  margin : 0 12.5px 0 0;
  //}
  //&:last-child {
  //  margin : 0 0 0 12.5px;
  //}
  
  
  .title {
    position      : relative;
    margin-bottom : 5px;
    //text-transform : capitalize;
    font-size     : 12px;
  }
  input[type='date'] {
    padding          : 0 15px;
    border-radius    : 8px;
    height           : 30px;
    width            : 100%;
    
    color            : #4F5A69;
    background-color : #FFFFFF;
    cursor           : text;
    //border           : 1px solid #94ACC2;
    border           : none;
    box-shadow       : @shadow;
    font-size        : 14px;
    &:focus {
      outline : none;
    }
  }
  
  &.bordered {
    input[type='date'] {
      border : 1px solid @dark-blue;
    }
  }
  
}

.file-input, .info-content .info-element.file-input {
  position      : relative;
  min-width     : 130px;
  height        : 60px;
  border-radius : 6px;
  background    : #FFFFFF;
  &:before {
    content       : '';
    position      : absolute;
    width         : calc(100% - 12px);
    height        : calc(100% - 12px);
    left          : 5px;
    top           : 5px;
    border-radius : 6px;
    border        : 1px #3B4452 dashed;
  }
  .unselected, .selected {
    position        : relative;
    width           : 100%;
    height          : 100%;
    color           : #3B4452;
    display         : flex;
    justify-content : center;
    align-items     : center;
    padding         : 0 15px;
    pointer-events  : none;
    text-align      : center;
  }
  .selected {
    display : none;
  }
  &.selected {
    .unselected {
      display : none;
    }
    .selected {
      display : flex;
      width   : auto;
      height  : calc(100% - 20px);
      margin  : 10px;
    }
  }
  input {
    display  : block;
    position : absolute !important;
    top      : 0;
    left     : 0;
    width    : 100%;
    height   : 100%;
    opacity  : 0;
    cursor   : pointer;
  }
}

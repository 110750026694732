@import '../common/variables';
@import '../common/mixins';

.work-field {
  position   : relative;
  flex       : 1 0 auto;
  padding    : 0 0 60px @paddingMenu;
  box-sizing : content-box;
  min-width: calc(1800px - @paddingMenu);

  .resp(@tablet-big, {
    padding    : 25px 0 60px 0;
  });

  .div-image-test {
    position : relative;
    width    : 100px;
    height   : 100px;
  }

}


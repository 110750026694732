@import '../common/variables';
@import '../common/mixins';

.checkbox-block {
  position : relative;
  width    : 100%;
  input {
    padding       : 0;
    height        : initial;
    width         : initial;
    margin-bottom : 0;
    display       : none;
    cursor        : pointer;
  }
  label {
    position     : relative;
    width        : 100%;
    padding-left : 32px;
    cursor       : pointer;
    &:before {
      content    : '';
      display    : block;
      position   : absolute;
      top        : -1px;
      left       : 0;
      width      : 20px;
      height     : 20px;
      background : url("@{svg-checkbox_0}.svg") no-repeat center/contain;
    }
    &:after {
      content       : '';
      display       : block;
      position      : absolute;
      top           : -1px;
      left          : 0;
      width         : 20px;
      height        : 20px;
      border-radius : 7px;
      opacity       : 0;
      background    : url("@{svg-checkbox_1}.svg") no-repeat center;
      transition    : opacity .3s ease-out;
    }
  }
  input:checked + label {
    &:after {
      opacity : 1;
    }
  }
}

.label-checkbox {
  position    : relative;
  padding     : 10px 10px;

  display     : flex;
  flex-flow   : row nowrap;
  align-items : center;
  input {
    border-right  : 1px solid #F6F6F6;
    padding       : 0 10px 0 0;
    height        : initial;
    width         : initial;
    margin-bottom : 0;
    display       : none;
    cursor        : pointer;
  }
  label {
    position    : relative;
    cursor      : pointer;
    display     : inline-flex;
    //height   : 24px;
    align-items : center;
  }
  label:before {
    content            : '';
    -webkit-appearance : none;
    background-color   : transparent;
    border             : 2px solid #2B323C;
    border-radius      : 6px;
    box-shadow         : 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding            : 10px;
    display            : inline-block;
    position           : relative;
    vertical-align     : middle;
    cursor             : pointer;
    margin-right       : 15px;
  }
  input:checked + label:after {
    content      : '';
    display      : block;
    position     : absolute;
    top          : 4px;
    left         : 9px;
    width        : 5px;
    height       : 11px;
    border       : solid #2B323C;
    border-width : 0 2px 2px 0;
    transform    : rotate(45deg);
  }
}
.checkbox-row {
  position         : relative;
  display          : flex;
  flex-flow        : row wrap;
  width            : 100%;
  height           : auto;
  //margin-bottom    : 4px;
  border-radius    : 6px;
  background-color : #FFFFFF;
  //overflow      : hidden;
  box-shadow       : @shadow;
  transition       : background-color .5s ease;
  &:last-child {
    margin-bottom : 15px;
  }
}

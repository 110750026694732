@import '../common/variables';
@import '../common/mixins';

.list {
  position   : fixed;
  left       : 0;
  top        : 0;
  width      : @paddinglist;
  padding    : 20px 0;
  height     : 100vh;
  background : @left-menu-color url('@{path-img}/bg-main.svg') no-repeat center/cover;
  overflow   : auto;

  flex-basis : @paddinglist;

  color      : #ffffff;
  font-size  : 12px;

  .list-element {
    position : relative;
    width    : 100%;
    .list-element-button {
      display     : flex;
      align-items : center;
      gap         : 5px;

      position    : relative;
      //height      : 32px;

      //position    : relative;
      padding     : 5px 15px 5px 25px;
      //border-bottom : 1px solid #3B4452;
      cursor      : pointer;
      transition  : background-color .3s ease-in-out;
      &:hover {
        //padding-left : 30px;
        //border-left : 3px solid #3B4452;
        //background-color : #484960;
        background-color : #25346c;
      }
      &.active {
        //border-left      : 3px solid #546072;
        //padding-left     : 30px;
        background-color : #f2f5f922;
        //color            : #222222;
      }
      &.selected {
        background : #f2f5f922;
      }
      .list-element-arrow {
        position     : absolute;
        left         : 7px;
        width        : 0;
        height       : 0;
        border-left  : 5px solid transparent;
        border-right : 5px solid transparent;

        border-top   : 5px solid #ffffff;
        &.open {
          transform : rotate(-90deg);
        }
      }
      .list-element-icon {
        flex-shrink: 0;

        position : relative;
        width    : 20px;
        height   : 10px;
      }
      .list-element-name {
        overflow      : hidden;
        text-overflow : ellipsis;
        white-space   : nowrap;
      }
    }
    .list-element-dropdown {
      position     : relative;
      padding-left : 20px;
      max-height   : 0;
      overflow     : hidden;

      &.open {
        max-height : 6000px;
      }
    }
  }
}
